const initialProduct = {
  order_id: 0,
  partner_id: 0,
  brand_id: 0,
  plan_id: 0,
  product_id: 0,
  staff_id: 0,
  license_count: 0,
  lifetime: "",
  payment_method_id: 0,
  action: "",
  note: "",
  plan_name: "",
  status: 0,
  installaticon: "",
  created_at: "",
};

const productReducer = (data, action) => {
  switch (action.type) {
    case "SET": {
      return {
        ...data,
        order_id: action.order_id,
        partner_id: action.partner_id,
        brand_id: action.brand_id,
        plan_id: action.plan_id,
        product_id: action.product_id,
        staff_id: action.staff_id,
        license_count: action.license_count,
        lifetime: action.lifetime,
        payment_method_id: action.payment_method_id,
        action: action.action,
        note: action.note,
        installaticon: action.installaticon,
        plan_name: action.plan_name,
        status: action.status,
        tracking_id: action.tracking_id,
        created_at: action.created_at,
      };
    }
    case "UPDATE": {
      return {
        ...data,
        [action.key]: action.value,
      };
    }
    default:
      return data;
  }
};

const initialCert = {
  common_name: "",
  en_org: "",
  en_org_unit: "",
  state: "",
  locality: "",
  password: "",
  expired_at: "",
};

const certReducer = (data, action) => {
  switch (action.type) {
    case "SET": {
      return {
        ...data,
        common_name: action.common_name,
        en_org: action.en_org,
        en_org_unit: action.en_org_unit,
        state: action.state,
        locality: action.locality,
        password: action.password,
        cert: action.cert,
        expired_at: action.expired_at,
      };
    }
    case "UPDATE": {
      return {
        ...data,
        [action.key]: action.value,
      };
    }
    default:
      return data;
  }
};

const initialCsr = {
  csr: "",
  private_key: "",
};

const csrReducer = (data, action) => {
  switch (action.type) {
    case "SET": {
      return {
        ...data,
        csr: action.csr,
        private_key: action.private_key,
      };
    }
    case "UPDATE": {
      return {
        ...data,
        [action.key]: action.value,
      };
    }
    default:
      return data;
  }
};

const initialInvoice = {
  total: 0,
  subtotal: 0,
  tax: 0,
};

const invoiceReducer = (data, action) => {
  switch (action.type) {
    case "SET": {
      return {
        ...data,
        total: action.total,
        subtotal: action.subtotal,
        tax: action.tax,
      };
    }
    case "UPDATE": {
      return {
        ...data,
        [action.key]: action.value,
      };
    }
    default:
      return data;
  }
};

const initialPic = {
  affl_type: "",
  email: "",
  last_name: "",
  first_name: "",
  kana_last_name: "",
  kana_first_name: "",
  org: "",
  kana_org: "",
  phone: "",
};

const picReducer = (data, action) => {
  switch (action.type) {
    case "SET": {
      return {
        ...data,
        affl_type: action.affl_type,
        email: action.email,
        last_name: action.last_name,
        first_name: action.first_name,
        kana_last_name: action.kana_last_name,
        kana_first_name: action.kana_first_name,
        org: action.org,
        kana_org: action.kana_org,
        phone: action.phone,
      };
    }
    case "UPDATE": {
      return {
        ...data,
        [action.key]: action.value,
      };
    }
    default:
      return data;
  }
};

const initialOrg = {
  web_server_type: "",
  email: "",
  phone: "",
  org: "",
  kana_org: "",
  en_org: "",
  org_unit: "",
  kana_org_unit: "",
  en_org_unit: "",
  postcode: "",
  state: "",
  city: "",
  en_city: "",
  address: "",
  en_address: "",
  duns_number: "",
  corp_number: "",
  tdb_code: "",
  tsr_code: "",
  current_cert: "",
  ceo: "",
  chief_last_name: "",
  kana_chief_last_name: "",
  chief_first_name: "",
  kana_chief_first_name: "",
  chief_org_unit: "",
  chief_role: "",
  signer_email: "",
  signer_last_name: "",
  signer_first_name: "",
  signer_org_unit: "",
  signer_phone: "",
  signer_role: "",
  en_seal_address: "",
  en_seal_building: "",
  en_signer_first_name: "",
  en_signer_last_name: "",
};

const orgReducer = (data, action) => {
  switch (action.type) {
    case "SET": {
      return {
        ...data,
        web_server_type: action.web_server_type,
        email: action.email,
        phone: action.phone,
        org: action.org,
        kana_org: action.kana_org,
        en_org: action.en_org,
        org_unit: action.org_unit,
        kana_org_unit: action.kana_org_unit,
        en_org_unit: action.en_org_unit,
        postcode: action.postcode,
        state: action.state,
        city: action.city,
        en_city: action.en_city,
        address: action.address,
        en_address: action.en_address,
        duns_number: action.duns_number,
        corp_number: action.corp_number,
        tdb_code: action.tdb_code,
        tsr_code: action.tsr_code,
        current_cert: action.current_cert,
        ceo: action.ceo,
        chief_last_name: action.chief_last_name,
        kana_chief_last_name: action.kana_chief_last_name,
        chief_first_name: action.chief_first_name,
        kana_chief_first_name: action.kana_chief_first_name,
        chief_org_unit: action.chief_org_unit,
        chief_role: action.chief_role,
        signer_email: action.signer_email,
        signer_last_name: action.signer_last_name,
        signer_first_name: action.signer_first_name,
        signer_org_unit: action.signer_org_unit,
        signer_phone: action.signer_phone,
        signer_role: action.signer_role,
        en_seal_address: action.en_seal_address,
        en_seal_building: action.en_seal_building,
        en_signer_last_name: action.en_signer_last_name,
        en_signer_first_name: action.en_signer_first_name,
      };
    }
    case "UPDATE": {
      return {
        ...data,
        [action.key]: action.value,
      };
    }
    default:
      return data;
  }
};

const initialOrgPic = {
  email: "",
  org: "",
  kana_org: "",
  en_org: "",
  org_unit: "",
  kana_org_unit: "",
  en_org_unit: "",
  role: "",
  en_role: "",
  last_name: "",
  first_name: "",
  kana_last_name: "",
  kana_first_name: "",
  en_last_name: "",
  en_first_name: "",
  postcode: "",
  state: 0,
  city: "",
  en_city: "",
  address: "",
  en_address: "",
  phone: "",
};

const orgPicReducer = (data, action) => {
  switch (action.type) {
    case "SET": {
      return {
        ...data,
        email: action.email,
        org: action.org,
        kana_org: action.kana_org,
        en_org: action.en_org,
        org_unit: action.org_unit,
        kana_org_unit: action.kana_org_unit,
        en_org_unit: action.en_org_unit,
        role: action.role,
        en_role: action.en_role,
        last_name: action.last_name,
        first_name: action.first_name,
        kana_last_name: action.kana_last_name,
        kana_first_name: action.kana_first_name,
        en_last_name: action.en_last_name,
        en_first_name: action.en_first_name,
        postcode: action.postcode,
        state: action.state,
        city: action.city,
        en_city: action.en_city,
        address: action.address,
        en_address: action.en_address,
        phone: action.phone,
      };
    }
    case "UPDATE": {
      return {
        ...data,
        [action.key]: action.value,
      };
    }
    default:
      return data;
  }
};

export {
  initialProduct,
  productReducer,
  initialCert,
  certReducer,
  initialInvoice,
  invoiceReducer,
  initialCsr,
  csrReducer,
  initialPic,
  picReducer,
  initialOrg,
  orgReducer,
  initialOrgPic,
  orgPicReducer,
};
