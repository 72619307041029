import React, { useState, useCallback, useEffect } from "react";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@material-ui/core";
import axios from "axios";

export default function FileSection(props) {
  const token = localStorage.getItem("jwt_token");
  const orderId = props.match.params.id;
  const [state, setState] = useState([]);

  const fetchInitData = useCallback(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/orders/${orderId}/files`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
        data: {},
      })
      .then(results => {
        setState(results.data);
        return;
      })
      .catch(error => {
        if (error.response.status === 401) {
          localStorage.removeItem("jwt_token");
          
          window.location.reload();
          return;
        }
        alert(error.response.data.errors);
      });
  }, [token, orderId, setState]);

  useEffect(() => {
    fetchInitData();
  }, [fetchInitData]);

  const handleChange = e => {
    props.setSelectedFiles({
      ...props.selectedFiles,
      [e.target.name]: e.target.checked,
    });
  };

  return (
    <React.Fragment>
      <Typography>添付ファイル</Typography>
      <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox
              disabled={!state.isDeliveryNote}
              checked={props.selectedFiles.isDeliveryNote}
              onChange={e => {
                handleChange(e);
              }}
              name="isDeliveryNote"
            />
          }
          label="納品書"
        />
        <FormControlLabel
          control={
            <Checkbox
              disabled={!state.isInvoice}
              checked={props.selectedFiles.isInvoice}
              onChange={e => {
                handleChange(e);
              }}
              name="isInvoice"
            />
          }
          label="請求書"
        />
        <FormControlLabel
          control={
            <Checkbox
              disabled={!state.isQuotation}
              checked={props.selectedFiles.isQuotation}
              onChange={e => {
                handleChange(e);
              }}
              name="isQuotation"
            />
          }
          label="見積書"
        />
        <FormControlLabel
          control={
            <Checkbox
              disabled={!state.isDeliverable}
              checked={props.selectedFiles.isDeliverable}
              onChange={e => {
                handleChange(e);
              }}
              name="isDeliverable"
            />
          }
          label="納品ZIPファイル"
        />
      </FormGroup>
    </React.Fragment>
  );
}
