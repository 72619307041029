import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
  },
  buttonRight: {
    textAlign: 'right'
  },
  dashedBorder: {
    border: "1px dashed",
    borderColor: theme.palette.primary.main,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginTop: theme.spacing(1),
  },
}));
