import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  TextField,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  CircularProgress,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { Edit as EditIcon } from "@material-ui/icons";
import MUIDataTable from "mui-datatables";
import axios from "axios";

// components
import PageTitle from "../../components/PageTitle";
import Widget from "../../components/Widget/Widget";

// styles
import useStyles from "./styles";

// local
import { statusConst } from "../../libs/Const";

export default function Order(props) {
  const token = localStorage.getItem("jwt_token");
  const [order, setOrder] = useState([]);
  const [orderId, setOrderId] = useState("");
  const [keyword, setKeyword] = useState("");
  const [commonName, setCommonName] = useState("");
  const [status, setStatus] = useState(0);
  const [searchAll, setSearchAll] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();

  const cetchOrders = useCallback(() => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/orders`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
        params: {
          all: 0,
          status: status,
          id: "",
          common_name: "",
          keyword: "",
        },
        data: {},
      })
      .then(results => {
        const orders = results.data.orders.map(row => {
          if (row.action === "renew") {
            row.action = "更新";
          } else {
            row.action = "新規";
          }

          if (row.lifetime === "1y") {
            row.lifetime = "1年";
          } else {
            row.lifetime = "2年";
          }

          switch (row.status) {
            case 0:
              row.status = "未着手";
              break;
            case 1:
              row.status = "回収完了";
              break;
            case 2:
              row.status = "キャンセル";
              break;
            case 3:
              row.status = "内容確認完了";
              break;
            case 4:
              row.status = "入荷待ち";
              break;
            case 5:
              row.status = "納品完了";
              break;
            case 6:
              row.status = "再発行";
              break;
            case 7:
              row.status = "設置中";
              break;
            case 8:
              row.status = "内容確認中";
              break;
            case 9:
              row.status = "更新済み";
              break;
            case 10:
              row.status = "入金待ち";
              break;
            case 11:
              row.status = "設置完了";
              break;
            case 12:
              row.status = "申請対応";
              break;
            case 13:
              row.status = "入金待ち(督促中)";
              break;
            case 14:
              row.status = "回収不能";
              break;
            case 15:
              row.status = "テスト";
              break;
            default:
              row.status = "不明";
              break;
          }
          row.edit = row.id;

          return row;
        });
        setOrder(orders);
        setIsLoading(false);
      })
      .catch(error => {
        if (error.response.status === 401) {
          localStorage.removeItem("jwt_token");

          window.location.reload();
          return;
        }
        alert(error.response.data.errors);
      });
  }, [status, setIsLoading, token]);

  useEffect(() => {
    cetchOrders();
  }, [cetchOrders]);

  const columns = [
    {
      name: "edit",
      label: " ",
      options: {
        filter: false,
        sort: false,
        download: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div onClick={e => editButtonClick(e, tableMeta.rowData)}>
              <IconButton color={"secondary"} size={"small"}>
                <EditIcon />
              </IconButton>
            </div>
          );
        },
      },
    },
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        //display: 'excluded',
      },
    },
    {
      name: "status",
      label: "進捗",
      options: {
        filter: true,
      },
    },
    {
      name: "created_at",
      label: "注文日",
      options: {
        filter: false,
      },
    },
    {
      name: "common_name",
      label: "コモンネーム",
      options: {
        filter: true,
      },
    },
    {
      name: "plan",
      label: "プラン",
      options: {
        filter: true,
      },
    },
    {
      name: "lifetime",
      label: "年数",
      options: {
        filter: true,
      },
    },
    {
      name: "action",
      label: "種別",
      options: {
        filter: true,
      },
    },
  ];

  const editButtonClick = (e, data) => {
    props.history.push(`/app/orders/${data[0]}`);
  };

  const searchButtonClick = e => {
    e.preventDefault();
    setIsLoading(true);
    let params = {
      all: searchAll ? 1 : 0,
      status: status,
      id: orderId,
      common_name: commonName,
      keyword: keyword,
    };
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/orders`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
        params: params,
        data: {},
      })
      .then(results => {
        setIsLoading(false);
        var orders = results.data.orders.map(row => {
          if (row.action === "renew") {
            row.action = "更新";
          } else {
            row.action = "新規";
          }

          if (row.lifetime === "1y") {
            row.lifetime = "1年";
          } else {
            row.lifetime = "2年";
          }

          switch (row.status) {
            case 0:
              row.status = "未着手";
              break;
            case 1:
              row.status = "回収完了";
              break;
            case 2:
              row.status = "キャンセル";
              break;
            case 3:
              row.status = "内容確認完了";
              break;
            case 4:
              row.status = "入荷待ち";
              break;
            case 5:
              row.status = "納品完了";
              break;
            case 6:
              row.status = "再発行";
              break;
            case 7:
              row.status = "設置中";
              break;
            case 8:
              row.status = "内容確認中";
              break;
            case 9:
              row.status = "更新済み";
              break;
            case 10:
              row.status = "入金待ち";
              break;
            case 11:
              row.status = "設置完了";
              break;
            case 12:
              row.status = "申請対応";
              break;
            case 13:
              row.status = "入金待ち(督促中)";
              break;
            case 14:
              row.status = "回収不能";
              break;
            case 15:
              row.status = "テスト";
              break;
            default:
              row.status = "不明";
              break;
          }
          row.edit = row.id;

          return row;
        });
        setOrder(orders);
      })
      .catch(error => {
        if (error.response.status === 401) {
          localStorage.removeItem("jwt_token");

          window.location.reload();
          return;
        }
        alert(error.response.data.errors);
      });
  };

  return (
    <>
      <PageTitle title="注文管理" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget disableWidgetMenu title="検索条件指定">
            <form id="search-form">
              <Grid container spacing={4}>
                <Grid item xs={6} sm={2}>
                  <TextField
                    fullWidth
                    type="number"
                    id="search-order-id"
                    label="オーダーID"
                    variant="outlined"
                    size="small"
                    onChange={e => setOrderId(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <FormControl fullWidth variant="outlined" size="small">
                    <InputLabel id="search-status-label">進捗</InputLabel>
                    <Select
                      labelId="search-status-label"
                      id="search-status"
                      value={status}
                      onChange={e => setStatus(e.target.value)}
                      label="進捗"
                    >
                      {statusConst.map(v => (
                        <MenuItem value={v.code} key={v.code}>
                          {v.friendlyName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    id="search-keyword"
                    type="search"
                    label="検索キーワード"
                    variant="outlined"
                    size="small"
                    onChange={e => setKeyword(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    fullWidth
                    id="search-common-name"
                    type="search"
                    label="コモンネーム"
                    variant="outlined"
                    size="small"
                    onChange={e => setCommonName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  {isLoading.search ? (
                    <CircularProgress size={26} className={classes.isLoader} />
                  ) : (
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      type="submit"
                      onClick={searchButtonClick}
                    >
                      検索
                    </Button>
                  )}
                </Grid>
                <Grid item xs={6} sm={2}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={searchAll}
                        onChange={e => setSearchAll(e.target.checked)}
                      />
                    }
                    label="すべて"
                  />
                </Grid>
              </Grid>
            </form>
          </Widget>
        </Grid>
        <Grid item xs={12}>
          <MUIDataTable
            title={"注文一覧"}
            data={order}
            columns={columns}
            options={{
              //selectableRowsHeader: false,
              selectableRows: "none",
              filter: false,
              //filterType: "multiselect",
              filterType: "checkbox",
              //responsive: 'scrollMaxHeight',
              search: false,
              //searchOpen: true,
              sort: true,
              rowHover: true,
              pagination: true,
              print: false,
              download: true,
              downloadOptions: {
                filename: "SSL市場注文リスト.csv",
                separator: ",",
                filterOptions: {
                  useDisplayedColumnsOnly: true,
                  useDisplayedRowsOnly: true,
                },
              },
              fixedHeader: true,
              rowsPerPage: 30,
              rowsPerPageOptions: [15, 30, 50, 100, 300],
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
