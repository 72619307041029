import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import {
  ArrowBack as ArrowBackIcon,
  AccountBox as AccountBoxIcon,
  People as PeopleIcon,
  ShoppingCart as ShoppingCartIcon,
  Description as DescriptionIcon,
  InsertDriveFile as InsertDriveFileIcon,
  MailOutline as MailOutlineIcon,
} from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";

const structure = [
  //{
  //  id: 0,
  //  label: "統計",
  //  link: "/app/dashboard",
  //  icon: <EqualizerIcon />,
  //},
  {
    id: 1,
    label: "注文管理",
    link: "/app/orders",
    icon: <ShoppingCartIcon />,
  },
  {
    id: 2,
    label: "顧客管理",
    link: "/app/accounts",
    icon: <AccountBoxIcon />,
  },
  //{
  //  id: 3,
  //  label: "発注",
  //  link: "",
  //  icon: <AddShoppingCartIcon />,
  //},
  //{
  //  id: 4,
  //  label: "CSR生成",
  //  link: "/app/csr/create",
  //  icon: <FiberNewIcon />,
  //},
  {
    id: 4,
    label: "CSR作成",
    link: "/app/csr",
    icon: <InsertDriveFileIcon />,
  },
  {
    id: 5,
    label: "テンプレート管理",
    link: "/app/mail/templates",
    icon: <MailOutlineIcon />,
  },
  {
    id: 6,
    label: "中間証明書管理",
    link: "/app/ca",
    icon: <DescriptionIcon />,
  },
  //{
  //  id: 15,
  //  label: "PDFサイン",
  //  link: "/app/sign",
  //  icon: <DoneAllIcon />,
  //},
  //{
  //  id: 1,
  //  label: "Typography",
  //  link: "/app/typography",
  //  icon: <TypographyIcon />,
  //},
  //{ id: 2, label: "Tables", link: "/app/tables", icon: <TableIcon /> },
  //{
  //  id: 3,
  //  label: "Notifications",
  //  link: "/app/notifications",
  //  icon: <NotificationsIcon />,
  //},
  //{
  //  id: 4,
  //  label: "UI Elements",
  //  link: "/app/ui",
  //  icon: <UIElementsIcon />,
  //  children: [
  //    { label: "Icons", link: "/app/ui/icons" },
  //    { label: "Charts", link: "/app/ui/charts" },
  //    { label: "Maps", link: "/app/ui/maps" },
  //  ],
  //},
  //{ id: 5, type: "divider" },
  //{ id: 6, type: "title", label: "HELP" },
  //{ id: 7, label: "Library", link: "", icon: <LibraryIcon /> },
  //{ id: 8, label: "Support", link: "", icon: <SupportIcon /> },
  //{ id: 9, label: "FAQ", link: "", icon: <FAQIcon /> },
  //{ id: 10, type: "divider" },
  //{ id: 11, type: "title", label: "PROJECTS" },
];

const adminStructure = [
  { id: 20, type: "divider" },
  { id: 21, type: "title", label: "ADMIN" },
  {
    id: 22,
    label: "スタッフ管理",
    link: "/app/admin/staffs",
    icon: <PeopleIcon />,
  },
];

function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);
  var isAdmin = localStorage.getItem("is_admin");

  useEffect(function() {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {structure.map(link => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          />
        ))}
        {isAdmin === "1" ? (
          adminStructure.map(link => (
            <SidebarLink
              key={link.id}
              location={location}
              isSidebarOpened={isSidebarOpened}
              {...link}
            />
          ))
        ) : (
          <React.Fragment></React.Fragment>
        )}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
