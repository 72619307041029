//import React from "react";
import React, { useState } from "react";
import { 
  Snackbar,
  Grid,
  Input,
  TextField,
  Button,
  CircularProgress,
  InputLabel,
  MenuItem,
  FormControl,
  Select
} from "@material-ui/core";
import { 
  CheckCircleOutline as CheckCircleOutlineIcon
} from '@material-ui/icons';
import dayjs from 'dayjs';
import axios from 'axios';

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";

// styles
import useStyles from "./styles";

dayjs.locale('ja');

export default function Sign(props) {
  const classes = useStyles();
  var [base64, setBase64] = useState("");
  var [resBase64, setResBase64] = useState("");
  var [name, setName] = useState("");
  var [date, setDate] = useState(dayjs().format('YYYY-MM-DD'));
  var [type, setType] = useState(1);
  var [number, setNumber] = useState("");
  var [isLoading, setIsLoading] = useState(false);
  var [states, setStates] = useState({
    open: false,
    vertical: 'bottom',
    horizontal: 'right',
  });
  var [position, setPosition] = useState(1);

  const { vertical, horizontal, open } = states;

  const handleClose = () => {
    setStates({ ...states, open: false });
  };


  const onChangeFile = (e) => {
    setResBase64("");
    var reader = new FileReader();
    let file = e.target.files[0];
    if (!file) {
      return;
    }
    setName(file.name);
    reader.onloadend = () => {
        setBase64(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleClick = (e) => {
    e.preventDefault();
    setIsLoading(true);
    let token = localStorage.getItem('jwt_token');
    if (!!base64 && !!name && !!date && !!type && !!number) {
      setTimeout(() => {
        axios.post(`${process.env.REACT_APP_API_URL}/api/v1/pdf/sign`, { 
          base64: base64, 
          name: name,
          date: date,
          type: type,
          number: number,
          position: position,
        }, {
          headers: {
            'Content-Type': 'application/json', 
            'Authorization': `bearer ${token}`
          }
        })
        .then((results) => {
          setResBase64("data:application/pdf;base64," + results.data.base64);
          setIsLoading(false);
          setStates({ open: true, vertical: 'bottom', horizontal: 'right', });
        })
        .catch((error) => {
          localStorage.removeItem("jwt_token");
          
          window.location.reload();
          return;
        });
      }, 2000);
    }
  }

  return (
    <>
      <PageTitle title="PDFサイン" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget disableWidgetMenu>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={4}>
                <TextField
                  id="board-no"
                  fullWidth
                  type="number"
                  label="Board 案件No."
                  onChange={e => setNumber(e.target.value)} 
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <form className={classes.container} noValidate>
                  <TextField
                    id="date"
                    fullWidth
                    label="受信年月日"
                    type="date"
                    defaultValue={date}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={e => setDate(e.target.value)} 
                  />
                </form>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel id="select-file-type">ファイルタイプ</InputLabel>
                  <Select
                    labelId="select-file-type"
                    id="select-file-type-id"
                    value={type}
                    onChange={e => setType(e.target.value)}>
                    <MenuItem value={1}>PDF</MenuItem>
                    <MenuItem value={2}>JPEG</MenuItem>
                    <MenuItem value={3}>PING</MenuItem>
                    <MenuItem value={4}>NONE</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel id="select-position">押印位置</InputLabel>
                  <Select
                    labelId="select-position"
                    id="select-position-id"
                    value={position}
                    onChange={e => setPosition(e.target.value)}>
                    <MenuItem value={1}>左上</MenuItem>
                    <MenuItem value={2}>右上</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Input 
                  fullWidth
                  type="file"
                  className={classes.inputFile}
                  onChange={onChangeFile}
                  inputProps={{
                    accept: "application/pdf,image/jpeg,image/png"
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4} className={classes.buttonRight}>
                {isLoading ? (
                  <CircularProgress size={26} className={classes.signLoader} />
                ) : (
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    startIcon={<CheckCircleOutlineIcon />}
                    disabled={ 
                      base64.length === 0
                      || date.length === 0
                      || name.length === 0
                      || number.length === 0
                    }
                    onClick={handleClick}>
                  押印
                  </Button>
                )}
              </Grid>
            </Grid>
          </Widget>
        </Grid>
      </Grid>
      {resBase64 ? (
      <div>
        <embed type="application/pdf" src={resBase64} width="100%" height="800px" />
      </div>
      ) : (
        <div>
          <embed type="application/pdf" src={base64} width="100%" height="800px" />
        </div>
      )}
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        key={`${vertical},${horizontal}`}
        open={open}
        onClose={handleClose}
        message="押印が成功しました" />
    </>
  );
}
