import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import Dashboard from "../../pages/dashboard";

import Sign from "../../pages/sign";
import Profile from "../../pages/profile";
import Staff from "../../pages/staffs";
import StaffEdit from "../../pages/staffs/edit/Index";
import StaffCreate from "../../pages/staffs/create/Index";
import Order from "../../pages/orders";
import OrderEdit from "../../pages/orders/edit";
import OrderMailEdit from "../../pages/orders/mail/edit/Index";
import OrderMailHistory from "../../pages/orders/mail/history/Index";
import Account from "../../pages/accounts";
import AccountEdit from "../../pages/accounts/edit";
import CSR from "../../pages/csr";
import Mail from "../../pages/mail/Index";
import MailEdit from "../../pages/mail/edit/Index";
import MailCreate from "../../pages/mail/create/Index";

import CA from "../../pages/ca/Index";
import CAEdit from "../../pages/ca/edit/Index";

// context
import { useLayoutState } from "../../context/LayoutContext";

function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} />
        <Sidebar />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <div className={classes.fakeToolbar} />
          <Switch>
            <Route path="/app/dashboard" component={Dashboard} />

            {/* プロフィール編集 */}
            <Route path="/app/profile" component={Profile} />

            {/* 注文管理 */}
            <Route exact path="/app/orders" component={Order} />
            <Route exact path="/app/orders/:id" component={OrderEdit} />

            {/* 顧客管理 */}
            <Route exact path="/app/accounts" component={Account} />
            <Route path="/app/accounts/:id" component={AccountEdit} />

            {/* メール作成 */}
            <Route
              path="/app/orders/:id/mail/edit/:tid"
              component={OrderMailEdit}
            />

            {/* メール送信履歴詳細 */}
            <Route
              path="/app/orders/:id/mail/historys/:hid"
              component={OrderMailHistory}
            />

            {/* メールテンプレート管理 */}
            <Route exact path="/app/mail/templates" component={Mail} />
            <Route
              exact
              path="/app/mail/templates/create"
              component={MailCreate}
            />
            <Route path="/app/mail/templates/:id" component={MailEdit} />

            {/* 中間証明書管理 */}
            <Route exact path="/app/ca" component={CA} />
            <Route path="/app/ca/:id" component={CAEdit} />

            {/* CSR作成 */}
            <Route path="/app/csr" component={CSR} />

            {/* サインイン */}
            <Route path="/app/sign" component={Sign} />

            {/* スタッフ管理 */}
            <Route exact path="/app/admin/staffs" component={Staff} />
            <Route path="/app/admin/staffs/edit/:id" component={StaffEdit} />
            <Route path="/app/admin/staffs/create" component={StaffCreate} />

            {/*
            <Route path="/app/typography" component={Typography} />
            <Route path="/app/tables" component={Tables} />
            <Route path="/app/notifications" component={Notifications} />
            <Route
              exact
              path="/app/ui"
              render={() => <Redirect to="/app/ui/icons" />}
            />
            <Route path="/app/ui/maps" component={Maps} />
            <Route path="/app/ui/icons" component={Icons} />
            <Route path="/app/ui/charts" component={Charts} />
            */}
          </Switch>
        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);
