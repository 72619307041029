import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    minWidth: 120,
  },
  buttonRight: {
    textAlign: 'right'
  },
  inputFile: {
    marginTop: theme.spacing(2),
  },
  signLoader: {
    marginLeft: theme.spacing(4),
  },
}));