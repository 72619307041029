import React, { useState } from "react";
import {
  Grid,
  TextField,
  Typography,
  Button,
  CircularProgress,
} from "@material-ui/core";
import axios from "axios";

// components
import Widget from "../../../components/Widget/Widget";

// styles
import useStyles from "../styles";

export default function CsrCreateByOrderId(props) {
  const classes = useStyles();
  const token = localStorage.getItem("jwt_token");
  const [csr, setCsr] = useState("");
  const [privateKey, setPrivateKey] = useState("");
  const [orderId, setOrderId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = async e => {
    e.preventDefault();
    setIsLoading(true);
    if (!orderId) {
      setIsLoading(false);
      alert("オーダーIDが指定されていません");
      return;
    }
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/v1/csr/create/${orderId}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
        },
      )
      .then(results => {
        setCsr(results.data.csr);
        setPrivateKey(results.data.private_key);
        setIsOpen(true);
        setIsLoading(false);
        return;
      })
      .catch(error => {
        setIsLoading(false);
        if (error.response.status === 401) {
          localStorage.removeItem("jwt_token");
          
          window.location.reload();
          return;
        }
        alert(error.response.data.errors);
      });
  };

  return (
    <React.Fragment>
      <Widget disableWidgetMenu>
        <Typography variant="h5" color="textSecondary">
          注文一覧から作成
        </Typography>
        <div className={classes.dashedBorder}>
          <form id="csr-create-by-order-form">
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  type="number"
                  id="order-id"
                  label="オーダーID"
                  variant="outlined"
                  size="small"
                  value={orderId}
                  onChange={e => {
                    setOrderId(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {isLoading ? (
                  <CircularProgress size={26} className={classes.isLoader} />
                ) : (
                  <Button
                    fullWidth
                    disabled={orderId.length === 0}
                    variant="contained"
                    color="primary"
                    type="summit"
                    onClick={e => {
                      handleClick(e);
                    }}
                  >
                    CSR作成
                  </Button>
                )}
              </Grid>
              {isOpen ? (
                <React.Fragment>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      readonly
                      type="text"
                      id="csr"
                      label="CSR"
                      variant="outlined"
                      size="small"
                      value={csr}
                      multiline
                      rows={30}
                      onChange={e => {
                        setCsr(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      readonly
                      id="privatekey"
                      type="search"
                      label="プライベートキー"
                      variant="outlined"
                      size="small"
                      multiline
                      rows={30}
                      value={privateKey}
                      onChange={e => {
                        setPrivateKey(e.target.value);
                      }}
                    />
                  </Grid>
                </React.Fragment>
              ) : (
                <React.Fragment></React.Fragment>
              )}
            </Grid>
          </form>
        </div>
      </Widget>
    </React.Fragment>
  );
}
