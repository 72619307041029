import React from "react";
import { Button } from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";

export default function CreateButton(props) {
  return (
    <Button
      fullWidth
      variant="outlined"
      color="primary"
      startIcon={<AddIcon />}
      onClick={e => {
        props.history.push(`/app/admin/staffs/create`);
      }}
    >
      新規登録
    </Button>
  );
}