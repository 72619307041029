import React, { useState } from "react";
import {
  Button,
  TextField,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  IconButton,
  Grid,
} from "@material-ui/core";
import { DeleteForever as DeleteForeverIcon } from "@material-ui/icons";
import axios from "axios";

// styles
import useStyles from "./styles";

export default function MemoCard(props) {
  const token = localStorage.getItem("jwt_token");
  const orderId = props.order_id;

  var classes = useStyles();
  var [isOpen, setIsOpen] = useState(false);

  const handleDeleteClick = async id => {
    await axios
      .delete(
        `${process.env.REACT_APP_API_URL}/api/v1/orders/${orderId}/memos/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
        },
      )
      .then(results => {
        fetchOrderMemoData();
        return;
      })
      .catch(error => {
        if (error.response.status === 401) {
          localStorage.removeItem("jwt_token");
          
          window.location.reload();
          return;
        }
        alert(error.response.data.errors);
      });
  };

  const fetchOrderMemoData = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/orders/${orderId}/memos`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
        data: {},
      })
      .then(results => {
        props.setMemos(results.data.memos);
      })
      .catch(error => {
        if (error.response.status === 401) {
          localStorage.removeItem("jwt_token");
          
          window.location.reload();
          return;
        }
        alert(error.response.data.errors);
      });
    return;
  };

  return (
    <Card variant="outlined" key={props.value.id}>
      <CardHeader
        title={props.value.name}
        subheader={props.value.created_at}
        action={
          <IconButton
            size="small"
            color="secondary"
            onClick={e => {
              setIsOpen(true);
            }}
          >
            <DeleteForeverIcon />
          </IconButton>
        }
      />
      <CardContent className={classes.comment}>
        <TextField
          fullWidth
          type="text"
          id={props.value.id + "-" + props.value.created_at}
          size="small"
          multiline
          rows={5}
          disabled
          value={props.value.body || ""}
        />
      </CardContent>
      {isOpen ? (
        <CardActions>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                onClick={e => {
                  handleDeleteClick(props.value.id);
                }}
              >
                削除する
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                fullWidth
                variant="outlined"
                color="secondary"
                onClick={e => {
                  setIsOpen(false);
                }}
              >
                キャンセル
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </Card>
  );
}
