import React, { useState } from "react";
import {
  Grid,
  Button,
  Modal,
  CircularProgress,
  Snackbar,
} from "@material-ui/core";
import axios from "axios";

// styles
import useStyles from "./styles";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

export default function OrderModal(props) {
  const token = localStorage.getItem("jwt_token");
  const orderId = props.match.params.id;
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const [isLoading, setIsLoading] = useState(false);
  const [isSnacbarOpen, setIsSnacbarOpen] = useState(false);

  const handleClose = () => {
    setIsLoading(false);
    props.setOrderModalOpen(false);
  };

  const handleClick = async e => {
    setIsLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/v1/orders/${orderId}/shop`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
        },
      )
      .then(results => {
        handleClose();
        setIsSnacbarOpen(true);
        window.location.reload();
        return;
      })
      .catch(error => {
        if (error.response.status === 401) {
          localStorage.removeItem("jwt_token");
          
          window.location.reload();
          return;
        }
        alert(
          "発注に失敗しました\n" +
            error.response.data.message.errors[0].message,
        );
      });
  };

  return (
    <React.Fragment>
      <Modal
        open={props.isOrderModalOpen}
        onClose={handleClose}
        aria-labelledby="order-modal-title"
        aria-describedby="order-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <h2 id="order-modal-title">発注</h2>
          <p id="order-modal-description">実行してよろしいですか？</p>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              {isLoading ? (
                <CircularProgress size={26} className={classes.isLoader} />
              ) : (
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  onClick={e => {
                    handleClick();
                  }}
                >
                  実行する
                </Button>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                fullWidth
                variant="outlined"
                color="secondary"
                onClick={e => {
                  handleClose();
                }}
              >
                キャンセル
              </Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        key={"bottom,right"}
        open={isSnacbarOpen}
        onClose={e => {
          setIsSnacbarOpen(false);
        }}
        message="正常に発注が完了しました"
      />
    </React.Fragment>
  );
}
