import React, { useState, useEffect, useCallback } from "react";
import { Grid, IconButton } from "@material-ui/core";
import { Edit as EditIcon } from "@material-ui/icons";
import MUIDataTable from "mui-datatables";
import axios from "axios";

// components
import PageTitle from "../../components/PageTitle/PageTitle";

export default function Index(props) {
  const token = localStorage.getItem("jwt_token");
  var [caKeys, setCaKeys] = useState([]);
  const columns = [
    {
      name: "edit",
      label: " ",
      options: {
        filter: false,
        sort: false,
        download: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div onClick={e => handleEditClick(e, tableMeta)}>
              <IconButton color={"secondary"} size={"small"}>
                <EditIcon />
              </IconButton>
            </div>
          );
        },
      },
    },
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
      },
    },
    {
      name: "brand",
      label: "ブランド",
      options: {
        filter: true,
      },
    },
    {
      name: "type",
      label: "認証タイプ",
      options: {
        filter: true,
      },
    },
    {
      name: "expiredAt",
      label: "有効期限",
      options: {
        filter: true,
      },
    },
    {
      name: "createdAt",
      label: "作成日",
      options: {
        filter: false,
      },
    },
  ];

  const handleEditClick = (e, data) => {
    props.history.push(`/app/ca/${data.rowData[1]}`);
  };

  const fetchData = useCallback(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/ca`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
        params: {},
        data: {},
      })
      .then(results => {
        setCaKeys(results.data);
        return;
      })
      .catch(error => {
        if (error.response.status === 401) {
          localStorage.removeItem("jwt_token");
          
          window.location.reload();
          return;
        }
        alert(error.response.data.errors);
      });
  }, [setCaKeys, token]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <React.Fragment>
      <PageTitle title="中間証明書管理" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <MUIDataTable
            data={caKeys}
            columns={columns}
            options={{
              selectableRows: "none",
              filter: false,
              filterType: "checkbox",
              search: false,
              sort: true,
              rowHover: true,
              pagination: true,
              print: false,
              fixedHeader: true,
              rowsPerPage: 30,
              rowsPerPageOptions: [15, 30, 50, 100, 300],
            }}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
