import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";

// components
import MemoCard from "./MemoCard";
import MemoCardNew from "./MemoCardNew";

export default function Memo(props) {
  const token = localStorage.getItem("jwt_token");
  const orderId = props.match.params.id;
  var [memos, setMemos] = useState([]);

  const fetchOrderMemoData = useCallback(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/orders/${orderId}/memos`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
        data: {},
      })
      .then(results => {
        setMemos(results.data.memos);
      })
      .catch(error => {
        if (error.response.status === 401) {
          localStorage.removeItem("jwt_token");
          
          window.location.reload();
          return;
        }
        alert(error.response.data.errors);
      });
  }, [token, orderId, setMemos]);

  useEffect(() => {
    fetchOrderMemoData();
  }, [fetchOrderMemoData]);

  return (
    <React.Fragment>
      <MemoCardNew order_id={orderId} setMemos={setMemos} />
      {memos.map(v => (
        <MemoCard
          value={v}
          key={"memo-" + v.id}
          order_id={orderId}
          setMemos={setMemos}
        />
      ))}
    </React.Fragment>
  );
}
