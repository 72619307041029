import React, { useState } from "react";
import { Grid, Button, Modal, CircularProgress } from "@material-ui/core";
import { MailOutline as MailOutlineIcon } from "@material-ui/icons";
import axios from "axios";

// styles
import useStyles from "./styles";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

export default function MailSendModal(props) {
  const token = localStorage.getItem("jwt_token");
  const orderId = props.match.params.id;
  const templateId = props.match.params.tid;
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    props.setIsSendModalOpen(false);
  };

  const handleClick = async e => {
    setIsLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/v1/orders/${orderId}/mail/send/${templateId}`,
        {
          email: props.email,
          subject: props.subject,
          body: props.body,
          files: props.selectedFiles
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
        },
      )
      .then(results => {
        setIsLoading(false);
        props.setIsSendModalOpen(false);
        props.setIsSnackbarOpen(true);
        return;
      })
      .catch(error => {
        setIsLoading(false);
        if (error.response.status === 401) {
          localStorage.removeItem("jwt_token");
          
          window.location.reload();
          return;
        }
        alert(error.response.data.errors);
      });
  };

  return (
    <Modal
      open={props.isSendModalOpen}
      onClose={handleClose}
      aria-labelledby="order-modal-title"
      aria-describedby="order-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        <h2 id="order-modal-title">メール送信</h2>
        <p id="order-modal-description">実行してよろしいですか？</p>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            {isLoading ? (
              <CircularProgress size={26} className={classes.isLoader} />
            ) : (
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                startIcon={<MailOutlineIcon />}
                onClick={e => {
                  handleClick(e);
                }}
              >
                実行する
              </Button>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              fullWidth
              variant="outlined"
              color="secondary"
              onClick={e => {
                handleClose();
              }}
            >
              キャンセル
            </Button>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
}
