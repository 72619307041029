const statusConst = [
  {
    code: 0,
    friendlyName: "未着手",
  },
  {
    code: 8,
    friendlyName: "内容確認中",
  },
  {
    code: 3,
    friendlyName: "内容確認完了",
  },
  {
    code: 12,
    friendlyName: "申請対応",
  },
  {
    code: 4,
    friendlyName: "入荷待ち",
  },
  {
    code: 7,
    friendlyName: "設置中",
  },
  {
    code: 11,
    friendlyName: "設置完了",
  },
  {
    code: 5,
    friendlyName: "納品完了",
  },
  {
    code: 10,
    friendlyName: "入金待ち",
  },
  {
    code: 13,
    friendlyName: "入金待ち(督促中)",
  },
  {
    code: 1,
    friendlyName: "回収完了",
  },
  {
    code: 6,
    friendlyName: "再発行",
  },
  {
    code: 2,
    friendlyName: "キャンセル",
  },
  {
    code: 14,
    friendlyName: "回収不能",
  },
  {
    code: 9,
    friendlyName: "更新済み",
  },
];

const actionConst = [
  {
    type: "new",
    friendlyName: "新規",
  },
  {
    type: "renew",
    friendlyName: "更新",
  },
];

const paymentMethodConst = [
  {
    type: 1,
    friendlyName: "クレジットカード",
  },
  {
    type: 2,
    friendlyName: "銀行振込み",
  },
];

const lifetimeConst = [
  {
    type: "1y",
    friendlyName: "1年",
  },
  {
    type: "2y",
    friendlyName: "2年",
  },
];

const afflTypeConst = [
  {
    type: "principal",
    friendlyName: "サイト等の所有組織",
  },
  {
    type: "agent",
    friendlyName: "別の組織（代理申し込み）",
  },
];

const partnerConst = [
  {
    code: 1,
    friendlyName: "一般",
  },
  {
    code: 2,
    friendlyName: "販売パートナー",
  },
];

const installaticonConst = [
  {
    type: "dedicated-server",
    friendlyName: "Joe's の専用サーバー",
  },
  {
    type: "vps",
    friendlyName: "Joe's のVPS",
  },
  {
    type: "shared-server",
    friendlyName: "Joe's の共用サーバー",
  },
  {
    type: "other",
    friendlyName: "その他",
  },
];

export {
  statusConst,
  actionConst,
  paymentMethodConst,
  lifetimeConst,
  afflTypeConst,
  partnerConst,
  installaticonConst,
};
