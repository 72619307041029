import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  isLoader: {
    marginLeft: theme.spacing(4),
  },
}));