export const states = [
  {
    zipcode: 1,
    friendlyName: "北海道",
    kKanaFriendlyName: "ホッカイドウ",
    kanaFriendlyName: "ほっかいどう",
    enFriendlyName: "Hokkaido",
  },
  {
    zipcode: 2,
    friendlyName: "青森県",
    kKanaFriendlyName: "アオモリケン",
    kanaFriendlyName: "あおもりけん",
    enFriendlyName: "Aomori",
  },
  {
    zipcode: 3,
    friendlyName: "岩手県",
    kKanaFriendlyName: "イワテケン",
    kanaFriendlyName: "いわてけん",
    enFriendlyName: "Iwate",
  },
  {
    zipcode: 4,
    friendlyName: "宮城県",
    kKanaFriendlyName: "ミヤギケン",
    kanaFriendlyName: "みやぎけん",
    enFriendlyName: "Miyagi",
  },
  {
    zipcode: 5,
    friendlyName: "秋田県",
    kKanaFriendlyName: "アキタケン",
    kanaFriendlyName: "あきたけん",
    enFriendlyName: "Akita",
  },
  {
    zipcode: 6,
    friendlyName: "山形県",
    kKanaFriendlyName: "ヤマガタケン",
    kanaFriendlyName: "やまがたけん",
    enFriendlyName: "Yamagata",
  },
  {
    zipcode: 7,
    friendlyName: "福島県",
    kKanaFriendlyName: "フクシマケン",
    kanaFriendlyName: "ふくしまけん",
    enFriendlyName: "Fukushima",
  },
  {
    zipcode: 8,
    friendlyName: "茨城県",
    kKanaFriendlyName: "イバラキケン",
    kanaFriendlyName: "いばらきけん",
    enFriendlyName: "Ibaraki",
  },
  {
    zipcode: 9,
    friendlyName: "栃木県",
    kKanaFriendlyName: "トチギケン",
    kanaFriendlyName: "とちぎけん",
    enFriendlyName: "Tochigi",
  },
  {
    zipcode: 10,
    friendlyName: "群馬県",
    kKanaFriendlyName: "グンマケン",
    kanaFriendlyName: "ぐんまけん",
    enFriendlyName: "Gunma",
  },
  {
    zipcode: 11,
    friendlyName: "埼玉県",
    kKanaFriendlyName: "サイタマケン",
    kanaFriendlyName: "さいたまけん",
    enFriendlyName: "Saitama",
  },
  {
    zipcode: 12,
    friendlyName: "千葉県",
    kKanaFriendlyName: "チバケン",
    kanaFriendlyName: "ちばけん",
    enFriendlyName: "Chiba",
  },
  {
    zipcode: 13,
    friendlyName: "東京都",
    kKanaFriendlyName: "トウキョウト",
    kanaFriendlyName: "とうきょうと",
    enFriendlyName: "Tokyo",
  },
  {
    zipcode: 14,
    friendlyName: "神奈川県",
    kKanaFriendlyName: "カナガワケン",
    kanaFriendlyName: "かながわけん",
    enFriendlyName: "Kanagawa",
  },
  {
    zipcode: 15,
    friendlyName: "新潟県",
    kKanaFriendlyName: "ニイガタケン",
    kanaFriendlyName: "にいがたけん",
    enFriendlyName: "Niigata",
  },
  {
    zipcode: 16,
    friendlyName: "富山県",
    kKanaFriendlyName: "トヤマケン",
    kanaFriendlyName: "とやまけん",
    enFriendlyName: "Toyama",
  },
  {
    zipcode: 17,
    friendlyName: "石川県",
    kKanaFriendlyName: "イシカワケン",
    kanaFriendlyName: "いしかわけん",
    enFriendlyName: "Ishikawa",
  },
  {
    zipcode: 18,
    friendlyName: "福井県",
    kKanaFriendlyName: "フクイケン",
    kanaFriendlyName: "ふくいけん",
    enFriendlyName: "Fukui",
  },
  {
    zipcode: 19,
    friendlyName: "山梨県",
    kKanaFriendlyName: "ヤマナシケン",
    kanaFriendlyName: "やまなしけん",
    enFriendlyName: "Yamanashi",
  },
  {
    zipcode: 20,
    friendlyName: "長野県",
    kKanaFriendlyName: "ナガノケン",
    kanaFriendlyName: "ながのけん",
    enFriendlyName: "Nagano",
  },
  {
    zipcode: 21,
    friendlyName: "岐阜県",
    kKanaFriendlyName: "ギフケン",
    kanaFriendlyName: "ぎふけん",
    enFriendlyName: "Gifu",
  },
  {
    zipcode: 22,
    friendlyName: "静岡県",
    kKanaFriendlyName: "シズオカケン",
    kanaFriendlyName: "しずおかけん",
    enFriendlyName: "Shizuoka",
  },
  {
    zipcode: 23,
    friendlyName: "愛知県",
    kKanaFriendlyName: "アイチケン",
    kanaFriendlyName: "あいちけん",
    enFriendlyName: "Aichi",
  },
  {
    zipcode: 24,
    friendlyName: "三重県",
    kKanaFriendlyName: "ミエケン",
    kanaFriendlyName: "みえけん",
    enFriendlyName: "Mie",
  },
  {
    zipcode: 25,
    friendlyName: "滋賀県",
    kKanaFriendlyName: "シガケン",
    kanaFriendlyName: "しがけん",
    enFriendlyName: "Shiga",
  },
  {
    zipcode: 26,
    friendlyName: "京都府",
    kKanaFriendlyName: "キョウトフ",
    kanaFriendlyName: "きょうとふ",
    enFriendlyName: "Kyoto",
  },
  {
    zipcode: 27,
    friendlyName: "大阪府",
    kKanaFriendlyName: "オオサカフ",
    kanaFriendlyName: "おおさかふ",
    enFriendlyName: "Osaka",
  },
  {
    zipcode: 28,
    friendlyName: "兵庫県",
    kKanaFriendlyName: "ヒョウゴケン",
    kanaFriendlyName: "ひょうごけん",
    enFriendlyName: "Hyogo",
  },
  {
    zipcode: 29,
    friendlyName: "奈良県",
    kKanaFriendlyName: "ナラケン",
    kanaFriendlyName: "ならけん",
    enFriendlyName: "Nara",
  },
  {
    zipcode: 30,
    friendlyName: "和歌山県",
    kKanaFriendlyName: "ワカヤマケン",
    kanaFriendlyName: "わかやまけん",
    enFriendlyName: "Wakayama",
  },
  {
    zipcode: 31,
    friendlyName: "鳥取県",
    kKanaFriendlyName: "トットリケン",
    kanaFriendlyName: "とっとりけん",
    enFriendlyName: "Tottori",
  },
  {
    zipcode: 32,
    friendlyName: "島根県",
    kKanaFriendlyName: "シマネケン",
    kanaFriendlyName: "しまねけん",
    enFriendlyName: "Shimane",
  },
  {
    zipcode: 33,
    friendlyName: "岡山県",
    kKanaFriendlyName: "オカヤマケン",
    kanaFriendlyName: "おかやまけん",
    enFriendlyName: "Okayama",
  },
  {
    zipcode: 34,
    friendlyName: "広島県",
    kKanaFriendlyName: "ヒロシマケン",
    kanaFriendlyName: "ひろしまけん",
    enFriendlyName: "Hiroshima",
  },
  {
    zipcode: 35,
    friendlyName: "山口県",
    kKanaFriendlyName: "ヤマグチケン",
    kanaFriendlyName: "やまぐちけん",
    enFriendlyName: "Yamaguchi",
  },
  {
    zipcode: 36,
    friendlyName: "徳島県",
    kKanaFriendlyName: "トクシマケン",
    kanaFriendlyName: "とくしまけん",
    enFriendlyName: "Tokushima",
  },
  {
    zipcode: 37,
    friendlyName: "香川県",
    kKanaFriendlyName: "カガワケン",
    kanaFriendlyName: "かがわけん",
    enFriendlyName: "Kagawa",
  },
  {
    zipcode: 38,
    friendlyName: "愛媛県",
    kKanaFriendlyName: "エヒメケン",
    kanaFriendlyName: "えひめけん",
    enFriendlyName: "Ehime",
  },
  {
    zipcode: 39,
    friendlyName: "高知県",
    kKanaFriendlyName: "コウチケン",
    kanaFriendlyName: "こうちけん",
    enFriendlyName: "Kochi",
  },
  {
    zipcode: 40,
    friendlyName: "福岡県",
    kKanaFriendlyName: "フクオカケン",
    kanaFriendlyName: "ふくおかけん",
    enFriendlyName: "Fukuoka",
  },
  {
    zipcode: 41,
    friendlyName: "佐賀県",
    kKanaFriendlyName: "サガケン",
    kanaFriendlyName: "さがけん",
    enFriendlyName: "Saga",
  },
  {
    zipcode: 42,
    friendlyName: "長崎県",
    kKanaFriendlyName: "ナガサキケン",
    kanaFriendlyName: "ながさきけん",
    enFriendlyName: "Nagasaki",
  },
  {
    zipcode: 43,
    friendlyName: "熊本県",
    kKanaFriendlyName: "クマモトケン",
    kanaFriendlyName: "くまもとけん",
    enFriendlyName: "Kumamoto",
  },
  {
    zipcode: 44,
    friendlyName: "大分県",
    kKanaFriendlyName: "オオイタケン",
    kanaFriendlyName: "おおいたけん",
    enFriendlyName: "Oita",
  },
  {
    zipcode: 45,
    friendlyName: "宮崎県",
    kKanaFriendlyName: "ミヤザキケン",
    kanaFriendlyName: "みやざきけん",
    enFriendlyName: "Miyazaki",
  },
  {
    zipcode: 46,
    friendlyName: "鹿児島県",
    kKanaFriendlyName: "カゴシマケン",
    kanaFriendlyName: "かごしまけん",
    enFriendlyName: "Kagoshima",
  },
  {
    zipcode: 47,
    friendlyName: "沖縄県",
    kKanaFriendlyName: "オキナワケン",
    kanaFriendlyName: "おきなわけん",
    enFriendlyName: "Okinawa",
  },
];
