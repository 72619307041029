import React, { useState, useCallback, useEffect } from "react";
import { Button, Grid } from "@material-ui/core";
import {
  OpenInNew as OpenInNewIcon,
  MailOutline as MailOutlineIcon,
  AddShoppingCart as AddShoppingCartIcon,
  Cancel as CancelIcon,
} from "@material-ui/icons";
import axios from "axios";

import OrderCancelModal from "./OrderCancelModal";
import OrderResendMailModal from "./OrderResendMailModal";
import OrderModal from "./OrderModal";

export default function OrderMenu(props) {
  const token = localStorage.getItem("jwt_token");
  const orderId = props.match.params.id;
  var [response, setResponse] = useState("");
  var [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  var [isResendMailModalOpen, setIsResendMailModalOpen] = useState(false);
  var [isRequestButtonOpen, setIsRequestButtonOpen] = useState(true);
  var [isLinkButtonOpen, setIsLinkButtonOpen] = useState(true);
  var [isCancelButtonOpen, setIsCancelButtonOpen] = useState(true);
  var [isOrderModalOpen, setOrderModalOpen] = useState(false);

  const fetchInitData = useCallback(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/orders/${orderId}/shop`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
        data: {},
      })
      .then(results => {
        setResponse(results.data.data);
        switch (results.data.data.brand) {
          case "digicert":
            if (results.data.data.is_request === 1) {
              setIsCancelButtonOpen(false);
              setIsLinkButtonOpen(false);
            } else {
              setIsRequestButtonOpen(false);
            }
            break;
          case "geotrust":
            if (results.data.data.is_request === 1) {
              setIsCancelButtonOpen(false);
              setIsLinkButtonOpen(false);
            } else {
              setIsRequestButtonOpen(false);
            }
            break;
          default:
        }
        return;
      })
      .catch(error => {
        if (error.response.status === 401) {
          localStorage.removeItem("jwt_token");
          
          window.location.reload();
          return;
        }
        alert(error.response.data.errors);
      });
  }, [setResponse, token, orderId]);

  useEffect(() => {
    fetchInitData();
  }, [fetchInitData]);

  return (
    <React.Fragment>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={2}>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            disabled={isRequestButtonOpen}
            startIcon={<AddShoppingCartIcon />}
            onClick={e => {
              setOrderModalOpen(true);
            }}
          >
            発注
          </Button>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            disabled={isCancelButtonOpen}
            startIcon={<CancelIcon />}
            onClick={e => {
              setIsCancelModalOpen(true);
            }}
          >
            発注取消
          </Button>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Button
            fullWidth
            variant="outlined"
            //href={response.link || "https://www.digicert.com/secure/orders"}
            color="secondary"
            //target="_blank"
            disabled={isLinkButtonOpen}
            endIcon={<OpenInNewIcon />}
            onClick={e => {
              window.open(
                `${response.link || "https://www.digicert.com/secure/orders"}`,
                "_blank",
              );
            }}
          >
            発注詳細
          </Button>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Button
            fullWidth
            variant="outlined"
            color="secondary"
            target="_blank"
            disabled={isLinkButtonOpen}
            startIcon={<MailOutlineIcon />}
            onClick={e => {
              setIsResendMailModalOpen(true);
            }}
          >
            認証メール送信
          </Button>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Button
            fullWidth
            variant="outlined"
            endIcon={<OpenInNewIcon />}
            onClick={e => {
              window.open("https://tech-unlimited.com/whois.html", "_blank");
            }}
          >
            WHOIS
          </Button>
        </Grid>
      </Grid>
      <OrderModal
        {...props}
        setOrderModalOpen={setOrderModalOpen}
        isOrderModalOpen={isOrderModalOpen}
      ></OrderModal>
      <OrderCancelModal
        {...props}
        isCancelModalOpen={isCancelModalOpen}
        setIsCancelModalOpen={setIsCancelModalOpen}
      />
      <OrderResendMailModal
        {...props}
        isResendMailModalOpen={isResendMailModalOpen}
        setIsResendMailModalOpen={setIsResendMailModalOpen}
      />
    </React.Fragment>
  );
}
