import React, { useState } from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
  Select,
  Typography,
  Button,
  CircularProgress,
} from "@material-ui/core";
import axios from "axios";

// components
import Widget from "../../../components/Widget/Widget";

// lib
import { states } from "../../../libs/States";

// styles
import useStyles from "../styles";

export default function CsrCreate(props) {
  const classes = useStyles();
  const token = localStorage.getItem("jwt_token");
  const [commonName, setCommonName] = useState("");
  const [enOrg, setEnOrg] = useState("");
  const [enOrgUnit, setEnOrgUnit] = useState("");
  const [enState, setEnState] = useState(0);
  const [enLocality, setEnLocality] = useState("");
  const [csr, setCsr] = useState("");
  const [privateKey, setPrivateKey] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleCreateClick = async e => {
    e.preventDefault();
    setIsLoading(true);
    if (!commonName || !enOrg || !enOrgUnit || !enState || !enLocality) {
      setIsLoading(false);
      alert("入力項目が足りません");
      return;
    }
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/v1/csr/create`,
        {
          common_name: commonName,
          en_org: enOrg,
          en_org_unit: enOrgUnit,
          state: enState,
          locality: enLocality,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
        },
      )
      .then(results => {
        setCsr(results.data.csr);
        setPrivateKey(results.data.private_key);
        setIsOpen(true);
        setIsLoading(false);
        return;
      })
      .catch(error => {
        if (error.response.status === 401) {
          localStorage.removeItem("jwt_token");
          
          window.location.reload();
          return;
        }
        alert(error.response.data.errors);
      });
  };

  return (
    <React.Fragment>
      <Widget disableWidgetMenu>
        <Typography variant="h5" color="textSecondary">
          SSL証明書申請情報
        </Typography>
        <form id="csr-create-form">
          <div className={classes.dashedBorder}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="search"
                  id="cert-common-name"
                  required
                  label="コモンネーム"
                  variant="outlined"
                  size="small"
                  value={commonName}
                  onChange={e => setCommonName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="search"
                  id="cert-en-org"
                  required
                  label="組織名（英）"
                  variant="outlined"
                  size="small"
                  value={enOrg}
                  onChange={e => setEnOrg(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="search"
                  id="cert-en-org-unit"
                  required
                  label="部門名（英）"
                  variant="outlined"
                  size="small"
                  value={enOrgUnit}
                  onChange={e => setEnOrgUnit(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant="outlined" size="small">
                  <InputLabel id="cert-en-state-label">
                    都道府県（英）
                  </InputLabel>
                  <Select
                    labelId="cert-en-state-label"
                    id="cert-en-state"
                    required
                    label="都道府県（英）"
                    placeholder="Osaka"
                    variant="outlined"
                    size="small"
                    value={enState}
                    onChange={e => setEnState(e.target.value)}
                  >
                    <MenuItem value={0} key={0} disabled>
                      指定なし
                    </MenuItem>
                    {states.map(v => (
                      <MenuItem value={v.zipcode} key={v.zipcode}>
                        {v.friendlyName}({v.enFriendlyName})
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="search"
                  id="cert-en-locality"
                  required
                  label="市区町村（英）"
                  variant="outlined"
                  size="small"
                  value={enLocality}
                  onChange={e => setEnLocality(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {isLoading ? (
                  <CircularProgress size={26} className={classes.isLoader} />
                ) : (
                  <Button
                    fullWidth
                    disabled={
                      commonName.length === 0 ||
                      enOrg.length === 0 ||
                      enOrgUnit.length === 0 ||
                      enLocality.length === 0
                    }
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={e => handleCreateClick(e)}
                  >
                    CSR作成
                  </Button>
                )}
              </Grid>
              {isOpen ? (
                <React.Fragment>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      type="text"
                      id="csr"
                      label="CSR"
                      variant="outlined"
                      size="small"
                      value={csr}
                      multiline
                      rows={30}
                      onChange={e => {
                        setCsr(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      id="privatekey"
                      type="search"
                      label="プライベートキー"
                      variant="outlined"
                      size="small"
                      multiline
                      rows={30}
                      value={privateKey}
                      onChange={e => {
                        setPrivateKey(e.target.value);
                      }}
                    />
                  </Grid>
                </React.Fragment>
              ) : (
                <React.Fragment></React.Fragment>
              )}
            </Grid>
          </div>
        </form>
      </Widget>
    </React.Fragment>
  );
}
