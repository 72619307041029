import React, { useState } from "react";
import { Grid, TextField } from "@material-ui/core";

// components
import PageTitle from "../../../components/PageTitle/PageTitle";
import Widget from "../../../components/Widget/Widget";
import SaveButton from "./components/SaveButton";

export default function Index(props) {
  var [title, setTitle] = useState(null);
  var [isTitleError, setIsTitleError] = useState(false);
  var [subject, setSubject] = useState(null);
  var [isSubjectError, setIsSubjectError] = useState(false);
  var [body, setBody] = useState(null);
  var [isBodyError, setIsBodyError] = useState(false);
  const data =
    "<<full_name>> 氏名\n\n<<plan_name>> プラン名\n\n<<common_name>> コモンネーム\n\n<<today>> 日付\n\n<<term>> 有効期限";

  return (
    <React.Fragment>
      <PageTitle title="メールテンプレート管理 > メールテンプレート登録" />
      <Grid container spacing={0}>
        <Grid item xs={9}>
          <Widget disableWidgetMenu>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12}>
                <Grid
                  container
                  spacing={4}
                  alignItems="flex-end"
                  justify="flex-end"
                >
                  <Grid item xs={12} sm={2}>
                    <SaveButton
                      {...props}
                      subject={subject}
                      body={body}
                      title={title}
                      setIsTitleError={setIsTitleError}
                      setIsSubjectError={setIsSubjectError}
                      setIsBodyError={setIsBodyError}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  required
                  error={isTitleError}
                  type="text"
                  id="create-template-title"
                  label="タイトル"
                  variant="outlined"
                  size="small"
                  value={title}
                  onChange={e => {
                    setTitle(e.target.value);
                    if (e.target.value) {
                      setIsTitleError(false);
                    } else {
                      setIsTitleError(true);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  required
                  error={isSubjectError}
                  type="text"
                  id="create-template-subject"
                  label="件名"
                  variant="outlined"
                  size="small"
                  value={subject}
                  onChange={e => {
                    setSubject(e.target.value);
                    if (e.target.value) {
                      setIsSubjectError(false);
                    } else {
                      setIsSubjectError(true);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  required
                  error={isBodyError}
                  type="text"
                  id="create-template-body"
                  label="本文"
                  variant="outlined"
                  multiline
                  rows={50}
                  value={body}
                  onChange={e => {
                    setBody(e.target.value);
                    if (e.target.value) {
                      setIsBodyError(false);
                    } else {
                      setIsBodyError(true);
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Widget>
        </Grid>
        <Grid item xs={3}>
          <Widget disableWidgetMenu title="置換項目一覧">
            <TextField
              fullWidth
              disabled
              type="text"
              id="create-template-body"
              variant="outlined"
              multiline
              rows={60}
              value={data}
            />
          </Widget>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
