import React, { useState, useCallback, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import axios from "axios";

// styles
import useStyles from "./styles";

export default function Notification(props) {
  const token = localStorage.getItem("jwt_token");
  const orderId = props.match.params.id;
  var classes = useStyles();
  var [response, setResponse] = useState([]);

  const fetchInitData = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/v1/orders/${orderId}/notification`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
          data: {},
        },
      )
      .then(results => {
        setResponse(results.data.messages);
        return;
      })
      .catch(error => {
        if (error.response.status === 401) {
          localStorage.removeItem("jwt_token");
          
          window.location.reload();
          return;
        }
        alert(error.response.data.errors);
      });
  }, [setResponse, token, orderId]);

  useEffect(() => {
    fetchInitData();
  }, [fetchInitData]);

  return (
    <React.Fragment>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12}>
          {response.map(res => (
            <div className={classes.notification} key={res.id}>
              <Alert severity="warning">{res.message}</Alert>
            </div>
          ))}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
