import React, { useState, useCallback, useEffect } from "react";
import {
  Button,
  Grid,
  Snackbar,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from "@material-ui/core";
import { CheckCircleOutline as CheckCircleOutlineIcon } from "@material-ui/icons";
import axios from "axios";

export default function SelectMailForm(props) {
  const token = localStorage.getItem("jwt_token");
  const orderId = props.match.params.id;
  var [response, setResponse] = useState([]);
  var [selectItemNo, setSelectItemNo] = useState(0);
  var [isSnacbarOpen, setIsSnacbarOpen] = useState(false);

  const fetchInitData = useCallback(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/mail/templates`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
        data: {},
      })
      .then(results => {
        setResponse(results.data);
        return;
      })
      .catch(error => {
        if (error.response.status === 401) {
          localStorage.removeItem("jwt_token");
          
          window.location.reload();
          return;
        }
        alert(error.response.data.errors);
      });
  }, [setResponse, token]);

  useEffect(() => {
    fetchInitData();
  }, [fetchInitData]);

  return (
    <React.Fragment>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={8}>
          <FormControl fullWidth variant="outlined" size="small">
            <InputLabel id="order-mail-template-label">
              メールテンプレート
            </InputLabel>
            <Select
              id="order-mail-template"
              labelId="order-mail-template-label"
              label="メールテンプレート"
              variant="outlined"
              //size="small"
              value={selectItemNo}
              onChange={e => {
                console.log(e.target.value);
                setSelectItemNo(e.target.value);
              }}
            >
              <MenuItem value={0} key={0} disabled>
                メールテンプレートを選択
              </MenuItem>
              {response.map(v => (
                <MenuItem value={v.id} key={v.id}>
                  {v.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            startIcon={<CheckCircleOutlineIcon />}
            onClick={e => {
              if (selectItemNo === 0) {
                alert("メールテンプレートが指定されていません。");
                return;
              }
              props.history.push(
                `/app/orders/${orderId}/mail/edit/${selectItemNo}`,
              );
            }}
          >
            メール作成
          </Button>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        key={"bottom,right"}
        open={isSnacbarOpen}
        onClose={e => {
          setIsSnacbarOpen(false);
        }}
        message="操作が正常に完了しました。"
      />
    </React.Fragment>
  );
}
