import React, { useState } from "react";
import { Snackbar, Button, CircularProgress } from "@material-ui/core";
import { Save as SaveIcon } from "@material-ui/icons";
import axios from "axios";

// styles
import useStyles from "./styles";

export default function Index(props) {
  const token = localStorage.getItem("jwt_token");
  var classes = useStyles();
  var [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  var [isLoading, setIsLoading] = useState(false);

  const handleSaveClick = async e => {
    setIsLoading(true);
    if (!props.title || !props.subject || !props.body) {
      if (!props.title) {
        props.setIsTitleError(true);
      }
      if (!props.subject) {
        props.setIsSubjectError(true);
      }
      if (!props.body) {
        props.setIsBodyError(true);
      }
      setIsLoading(false);
      return;
    }
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/v1/mail/templates`,
        {
          title: props.title,
          subject: props.subject,
          body: props.body,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
        },
      )
      .then(results => {
        setIsLoading(false);
        setIsSnackbarOpen(true);
        return;
      })
      .catch(error => {
        if (error.response.status === 401) {
          localStorage.removeItem("jwt_token");
          
          window.location.reload();
          return;
        }
        alert(error.response.data.errors);
      });
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <CircularProgress size={26} className={classes.isLoader} />
      ) : (
        <Button
          fullWidth
          variant="contained"
          color="primary"
          size="large"
          startIcon={<SaveIcon />}
          onClick={e => {
            handleSaveClick(e);
          }}
        >
          新規登録
        </Button>
      )}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        key={"bottom,right"}
        open={isSnackbarOpen}
        onClose={e => {
          setIsSnackbarOpen(false);
        }}
        message="正常に登録できました"
      />
    </React.Fragment>
  );
}
