import React, { useState, useEffect, useCallback } from "react";
import { Button, Grid, Snackbar, TextField } from "@material-ui/core";
import { MailOutline as MailOutlineIcon } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import axios from "axios";

// components
import PageTitle from "../../../../components/PageTitle/PageTitle";
import Widget from "../../../../components/Widget/Widget";
import MailSendModal from "./components/MailSendModal";
import FileSection from "./components/FileSection";

// styles
import useStyles from "./styles";

export default function Index(props) {
  const orderId = props.match.params.id;
  const templateId = props.match.params.tid;
  const token = localStorage.getItem("jwt_token");
  const classes = useStyles();
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [isSendModalOpen, setIsSendModalOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [isEmailError, setIsEmailError] = useState(false);
  const [isSubjectError, setIsSubjectError] = useState(false);
  const [isBodyError, setIsBodyError] = useState(false);
  const [warnings, setWarnings] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState({
    isDeliveryNote: false,
    isInvoice: false,
    isQuotation: false,
    isDeliverable: false,
  });

  const fetchInitData = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/v1/orders/${orderId}/mail/create/${templateId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
          data: {},
        },
      )
      .then(results => {
        setBody(results.data.body);
        setSubject(results.data.subject);
        setEmail(results.data.email);
        setWarnings(results.data.warnings);
      })
      .catch(error => {
        if (error.response.status === 401) {
          localStorage.removeItem("jwt_token");

          window.location.reload();
          return;
        }
        alert(error.response.data.errors);
      });
  }, [token, orderId, templateId]);

  useEffect(() => {
    fetchInitData();
  }, [fetchInitData]);

  return (
    <React.Fragment>
      <PageTitle title="注文管理 > 注文情報詳細 > メール作成" />
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Widget disableWidgetMenu>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                {warnings.map(res => (
                  <div className={classes.notification} key={res.index}>
                    <Alert severity="warning">{res.message}</Alert>
                  </div>
                ))}
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={2}>
                    <Button
                      fullWidth
                      variant="contained"
                      //size="large"
                      color="primary"
                      startIcon={<MailOutlineIcon />}
                      onClick={e => {
                        setIsSendModalOpen(true);
                      }}
                    >
                      送信
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  required
                  error={isEmailError}
                  type="text"
                  id="renewal-email"
                  label="宛先"
                  variant="outlined"
                  size="small"
                  value={email}
                  onChange={e => {
                    setEmail(e.target.value);
                    if (e.target.value.match(/.+@.+\..+/) == null) {
                      setIsEmailError(true);
                    } else {
                      setIsEmailError(false);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  required
                  error={isSubjectError}
                  type="text"
                  id="renewal-subject"
                  label="件名"
                  variant="outlined"
                  size="small"
                  value={subject}
                  onChange={e => {
                    setSubject(e.target.value);
                    if (e.target.value) {
                      setIsSubjectError(false);
                    } else {
                      setIsSubjectError(true);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FileSection
                  {...props}
                  setSelectedFiles={setSelectedFiles}
                  selectedFiles={selectedFiles}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  required
                  error={isBodyError}
                  type="text"
                  id="renewal-body"
                  label="本文"
                  variant="outlined"
                  multiline
                  rows={50}
                  value={body}
                  onChange={e => {
                    setBody(e.target.value);
                    if (e.target.value) {
                      setIsBodyError(false);
                    } else {
                      setIsBodyError(true);
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Widget>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        key={"bottom,right"}
        open={isSnackbarOpen}
        onClose={e => {
          setIsSnackbarOpen(false);
        }}
        message="操作が正常に完了しました。"
      />
      <MailSendModal
        {...props}
        email={email}
        subject={subject}
        body={body}
        setIsSendModalOpen={setIsSendModalOpen}
        selectedFiles={selectedFiles}
        isSendModalOpen={isSendModalOpen}
        setIsSnackbarOpen={setIsSnackbarOpen}
      />
    </React.Fragment>
  );
}
