import React, { useState, useCallback, useEffect } from "react";
import { Grid } from "@material-ui/core";
import axios from "axios";

import FileSectionDeliveryNote from "./FileSectionDeliveryNote";
import FileSectionInvoice from "./FileSectionInvoice";
import FileSectionQuotation from "./FileSectionQuotation";
import FileSectionDeliverable from "./FileSectionDeliverable";

export default function FileSection(props) {
  const token = localStorage.getItem("jwt_token");
  const orderId = props.match.params.id;
  const [isDeliveryNote, setIsDeliveryNote] = useState(false);
  const [isInvoice, setIsInvoice] = useState(false);
  const [isQuotation, setIsQuotation] = useState(false);
  const [isDeliverable, setIsDeliverable] = useState(false);
  const [imgToken, setImgToken] = useState("");

  const fetchInitData = useCallback(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/orders/${orderId}/files`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
        data: {},
      })
      .then(results => {
        setImgToken(results.data.imgToken);
        setIsDeliveryNote(results.data.isDeliveryNote);
        setIsInvoice(results.data.isInvoice);
        setIsQuotation(results.data.isQuotation);
        setIsDeliverable(results.data.isDeliverable);
        return;
      })
      .catch(error => {
        if (error.response.status === 401) {
          localStorage.removeItem("jwt_token");
          
          window.location.reload();
          return;
        }
        alert(error.response.data.errors);
      });
  }, [
    token,
    orderId,
    setIsDeliveryNote,
    setIsInvoice,
    setIsQuotation,
    setIsDeliverable,
  ]);

  useEffect(() => {
    fetchInitData();
  }, [fetchInitData]);

  return (
    <React.Fragment>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12}>
          <FileSectionDeliveryNote
            {...props}
            isDeliveryNote={isDeliveryNote}
            imgToken={imgToken}
            fetchInitData={fetchInitData}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <FileSectionInvoice
            {...props}
            isInvoice={isInvoice}
            imgToken={imgToken}
            fetchInitData={fetchInitData}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <FileSectionQuotation
            {...props}
            isQuotation={isQuotation}
            imgToken={imgToken}
            fetchInitData={fetchInitData}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <FileSectionDeliverable
            {...props}
            isDeliverable={isDeliverable}
            imgToken={imgToken}
            fetchInitData={fetchInitData}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
