import React, { useState } from "react";
import {
  Button,
  TextField,
  Card,
  CardContent,
  CardActions,
  Grid,
} from "@material-ui/core";
import axios from "axios";

// styles
import useStyles from "./styles";

export default function MemoCardNew(props) {
  const token = localStorage.getItem("jwt_token");
  const orderId = props.order_id;
  var classes = useStyles();
  var [memo, setMemo] = useState("");

  const handleWriteClick = async e => {
    if (memo === "") {
      return;
    }
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/v1/orders/${orderId}/memos`,
        {
          value: memo,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
        },
      )
      .then(results => {
        setMemo("");
        fetchOrderMemoData();
        return;
      })
      .catch(error => {
        if (error.response.status === 401) {
          localStorage.removeItem("jwt_token");
          
          window.location.reload();
          return;
        }
        alert(error.response.data.errors);
      });
  };

  const fetchOrderMemoData = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/orders/${orderId}/memos`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
        data: {},
      })
      .then(results => {
        props.setMemos(results.data.memos);
      })
      .catch(error => {
        if (error.response.status === 401) {
          localStorage.removeItem("jwt_token");
          
          window.location.reload();
          return;
        }
        alert(error.response.data.errors);
      });
    return;
  };

  return (
    <Card variant="outlined">
      <CardContent className={classes.comment}>
        <TextField
          fullWidth
          type="search"
          id=""
          size="small"
          variant="outlined"
          label="メモ"
          multiline
          rows={5}
          value={memo}
          onChange={e => {
            setMemo(e.target.value);
          }}
        />
      </CardContent>
      <CardActions>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <Button
              fullWidth
              disabled={memo.length === 0}
              variant="contained"
              color="primary"
              onClick={e => {
                handleWriteClick(e);
              }}
            >
              書き込む
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              fullWidth
              disabled={memo.length === 0}
              variant="outlined"
              color="primary"
              onClick={e => setMemo("")}
            >
              キャンセル
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
}
