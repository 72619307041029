//import React from "react";
import React, { useState, useEffect } from "react";
import {
  Grid,
  Input,
  Button,
  IconButton,
  Typography,
  Fade,
  CircularProgress,
  InputLabel,
  FormControl,
  FormHelperText,
  InputAdornment,
} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import {
  MailOutline as MailOutlineIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  Save as SaveIcon,
} from "@material-ui/icons";
import dayjs from "dayjs";
import axios from "axios";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";

// styles
import useStyles from "./styles";

dayjs.locale("ja");

export default function Profile(props) {
  const classes = useStyles();
  const token = localStorage.getItem("jwt_token");

  var [email, setEmail] = useState("");
  var [password, setPassword] = useState("");
  var [rePassword, setRePassword] = useState("");
  var [showPassword, setShowPassword] = useState("");
  var [error, setError] = useState(null);
  var [errorMsg, setErrorMsg] = useState("");
  var [isLoading, setIsLoading] = useState(false);
  var [states, setStates] = useState({
    open: false,
    vertical: "bottom",
    horizontal: "right",
  });

  const { vertical, horizontal, open } = states;

  const handleClose = () => {
    setStates({ ...states, open: false });
  };

  const handleMouseDownPassword = e => {
    e.preventDefault();
  };

  const handleClick = e => {
    setIsLoading(true);
    if (!!email && !!password) {
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/api/v1/staffs/profile`,
          {
            email: email,
            password: password,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `bearer ${token}`,
            },
          },
        )
        .then(results => {
          setEmail(results.data.email);
          setIsLoading(false);
          setStates({ open: true, vertical: "bottom", horizontal: "right" });
        })
        .catch(error => {
          if (error.response.status === 401) {
            localStorage.removeItem("jwt_token");
            
            window.location.reload();
            return;
          }
          setErrorMsg(error);
          setError(true);
          setIsLoading(false);
        });
    } else {
      setErrorMsg("すべての項目を入力してください!!");
      setError(true);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/staffs/profile`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
        data: {},
      })
      .then(results => {
        setEmail(results.data.email);
      })
      .catch(error => {
        if (error.response.status === 401) {
          localStorage.removeItem("jwt_token");
          
          window.location.reload();
          return;
        }
        alert(error.response.data.errors);
      });
  }, [token, setEmail, props]);

  return (
    <>
      <PageTitle title="プロフィール" />
      <Grid container spacing={4} alignItems="center" justify="center">
        <Grid item xs={10}>
          <Widget title="EMAIL & PASSWORD" disableWidgetMenu>
            <div className={classes.dashedBorder}>
              <Fade in={error}>
                <Typography color="secondary" className={classes.errorMessage}>
                  {errorMsg}
                </Typography>
              </Fade>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={12}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="email">メールアドレス</InputLabel>
                    <Input
                      id="email"
                      type="email"
                      value={email}
                      onChange={e => {
                        setEmail(e.target.value);
                      }}
                      startAdornment={
                        <MailOutlineIcon position="start"></MailOutlineIcon>
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth className={classes.margin}>
                    <InputLabel htmlFor="password">パスワード</InputLabel>
                    <Input
                      id="password"
                      type={showPassword ? "text" : "password"}
                      onChange={e => {
                        setPassword(e.target.value);
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl
                    fullWidth
                    className={classes.margin}
                    error={password !== rePassword}
                  >
                    <InputLabel htmlFor="repassword">再パスワード</InputLabel>
                    <Input
                      id="repassword"
                      type={showPassword ? "text" : "password"}
                      onChange={e => {
                        setRePassword(e.target.value);
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={e => {
                              setShowPassword(true);
                            }}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {password !== rePassword ? (
                      <FormHelperText id="repassword-helper-text" error>
                        パスワードが一致していません
                      </FormHelperText>
                    ) : (
                      <div></div>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} className={classes.buttonRight}>
                  {isLoading ? (
                    <CircularProgress
                      size={26}
                      className={classes.loginLoader}
                    />
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      startIcon={<SaveIcon />}
                      disabled={
                        password !== rePassword ||
                        email.length === 0 ||
                        password.length === 0
                      }
                      onClick={handleClick}
                    >
                      更新
                    </Button>
                  )}
                </Grid>
              </Grid>
            </div>
          </Widget>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        key={`${vertical},${horizontal}`}
        open={open}
        onClose={handleClose}
        message="更新が成功しました"
      />
    </>
  );
}
