import React, { useState, useCallback, useEffect } from "react";
import { Grid, Button, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Launch as LaunchIcon } from "@material-ui/icons";
import axios from "axios";

export default function MailHistory(props) {
  const token = localStorage.getItem("jwt_token");
  const orderId = props.match.params.id;
  const [response, setResponse] = useState([]);

  const fetchInitData = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/v1/orders/${orderId}/mail/historys`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
          data: {},
        },
      )
      .then(results => {
        setResponse(results.data);
        return;
      })
      .catch(error => {
        if (error.response.status === 401) {
          localStorage.removeItem("jwt_token");

          window.location.reload();
          return;
        }
        alert(error.response.data.errors);
      });
  }, [setResponse, token, orderId]);

  useEffect(() => {
    fetchInitData();
  }, [fetchInitData]);

  return (
    <React.Fragment>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12}>
          {(() => {
            if (response.length > 0) {
              return response.map(row => (
                <Alert
                  key={row.id}
                  icon={false}
                  variant="outlined"
                  severity="success"
                  action={
                    <Button
                      color="inherit"
                      size="small"
                      endIcon={<LaunchIcon />}
                      onClick={e => {
                        props.history.push(
                          `/app/orders/${orderId}/mail/historys/${row.id}`,
                        );
                      }}
                    >
                      詳細
                    </Button>
                  }
                >
                  送信日時: {row.createdAt} - 件名: {row.subject}
                </Alert>
              ));
            } else {
              return (
                <Typography variant="h5" color="textSecondary">
                  履歴はありません
                </Typography>
              );
            }
          })()}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
