import React, { useState } from "react";
import { Grid, Button, Modal, Snackbar } from "@material-ui/core";
import axios from "axios";

// styles
import useStyles from "./styles";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

export default function OrderCancelModal(props) {
  const token = localStorage.getItem("jwt_token");
  const orderId = props.match.params.id;
  var classes = useStyles();
  var [modalStyle] = useState(getModalStyle);
  var [isSnacbarOpen, setIsSnacbarOpen] = useState(false);

  const handleClose = () => {
    props.setIsCancelModalOpen(false);
  };

  const handleClick = async e => {
    await axios
      .put(
        `${process.env.REACT_APP_API_URL}/api/v1/orders/${orderId}/shop/cancel`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
        },
      )
      .then(results => {
        handleClose();
        setIsSnacbarOpen(true);
        window.location.reload();
        return;
      })
      .catch(error => {
        props.setIsCancelModalOpen(false);
        if (error.response.status === 401) {
          localStorage.removeItem("jwt_token");
          
          window.location.reload();
        }
        alert(
          "発注取消に失敗しました\n" +
            error.response.data.message.errors[0].message,
        );
        return;
      });
  };

  return (
    <React.Fragment>
      <Modal
        open={props.isCancelModalOpen}
        onClose={handleClose}
        aria-labelledby="order-modal-title"
        aria-describedby="order-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <h2 id="order-modal-title">発注取消</h2>
          <p id="order-modal-description">実行してよろしいですか？</p>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                onClick={e => {
                  handleClick();
                }}
              >
                実行する
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                fullWidth
                variant="outlined"
                color="secondary"
                onClick={e => {
                  handleClose();
                }}
              >
                キャンセル
              </Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        key={"bottom,right"}
        open={isSnacbarOpen}
        onClose={e => {
          setIsSnacbarOpen(false);
        }}
        message="発注の取り消しが完了しました"
      />
    </React.Fragment>
  );
}
