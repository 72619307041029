//import React from "react";
import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  Input,
  Button,
  IconButton,
  Typography,
  CircularProgress,
  InputLabel,
  FormControl,
  FormHelperText,
  InputAdornment,
  Snackbar,
  Select,
  MenuItem,
  TextField,
} from "@material-ui/core";
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  Save as SaveIcon,
} from "@material-ui/icons";
import axios from "axios";

// components
import PageTitle from "../../../components/PageTitle/PageTitle";
import Widget from "../../../components/Widget/Widget";

// styles
import useStyles from "./styles";

export default function Index(props) {
  const classes = useStyles();
  const token = localStorage.getItem("jwt_token");
  const staffId = props.match.params.id;
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [isAdmin, setIsAdmin] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [showPassword, setShowPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

  //const handleMouseDownPassword = e => {
  //  e.preventDefault();
  //};

  const fetchinitData = useCallback(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/staffs/admin/${staffId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
        params: {},
        data: {},
      })
      .then(results => {
        setIsLoading(false);
        setEmail(results.data.email);
        setName(results.data.name);
        setIsAdmin(results.data.isAdmin);
        return;
      })
      .catch(error => {
        setIsLoading(false);
        if (error.response.status === 401) {
          localStorage.removeItem("jwt_token");
          
          window.location.reload();
          return;
        }
        alert(error.response.data.errors);
      });
  }, [staffId, token]);

  useEffect(() => {
    fetchinitData();
  }, [fetchinitData]);

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);
    await axios
      .put(
        `${process.env.REACT_APP_API_URL}/api/v1/staffs/admin/${staffId}`,
        {
          email: email,
          name: name,
          isAdmin: isAdmin,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
        },
      )
      .then(results => {
        setIsSnackbarOpen(true);
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
        if (error.response.status === 401) {
          localStorage.removeItem("jwt_token");
          
          window.location.reload();
          return;
        }
      });
  };

  const handlePasswordUpdateClick = async e => {
    setIsLoading(true);
    await axios
      .put(
        `${process.env.REACT_APP_API_URL}/api/v1/staffs/admin/${staffId}/password/update`,
        {
          email: email,
          password: password,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
        },
      )
      .then(results => {
        setIsSnackbarOpen(true);
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
        if (error.response.status === 401) {
          localStorage.removeItem("jwt_token");
          
          window.location.reload();
        }
        alert("パスワードの更新に失敗しました。");
        return;
      });
  };

  return (
    <>
      <PageTitle title="スタッフ管理 > スタッフ情報編集" />
      <Grid container spacing={4} alignItems="center" justify="center">
        <Grid item xs={12}>
          <Widget disableWidgetMenu>
            <Typography variant="h5" color="textSecondary">
              基本情報
            </Typography>
            <div className={classes.dashedBorder}>
              <form id="admin-staff-form">
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      type="email"
                      id="email"
                      label="メールアドレス"
                      variant="outlined"
                      size="small"
                      value={email}
                      onChange={e => {
                        setEmail(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      fullWidth
                      type="name"
                      id="name"
                      label="氏名"
                      variant="outlined"
                      size="small"
                      value={name}
                      onChange={e => {
                        setName(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormControl fullWidth variant="outlined" size="small">
                      <InputLabel id="is-admin-label">権限</InputLabel>
                      <Select
                        labelId="is-admin-label"
                        id="is-admin"
                        label="権限"
                        value={isAdmin}
                        onChange={e => {
                          setIsAdmin(e.target.value);
                        }}
                      >
                        <MenuItem value={0} key={1}>
                          一般
                        </MenuItem>
                        <MenuItem value={1} key={2}>
                          管理者
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    {isLoading ? (
                      <CircularProgress
                        size={26}
                        className={classes.loginLoader}
                      />
                    ) : (
                      <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        startIcon={<SaveIcon />}
                        disabled={email.length === 0 || name.length === 0}
                        onClick={e => {
                          handleSubmit(e);
                        }}
                      >
                        更新
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </form>
            </div>
          </Widget>
        </Grid>

        <Grid item xs={12}>
          <Widget disableWidgetMenu>
            <Typography variant="h5" color="textSecondary">
              パスワード
            </Typography>
            <div className={classes.dashedBorder}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth className={classes.margin}>
                    <InputLabel htmlFor="password">パスワード</InputLabel>
                    <Input
                      id="password"
                      variant="outlined"
                      type={showPassword ? "text" : "password"}
                      onChange={e => {
                        setPassword(e.target.value);
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl
                    fullWidth
                    className={classes.margin}
                    error={password !== rePassword}
                  >
                    <InputLabel htmlFor="repassword">再パスワード</InputLabel>
                    <Input
                      id="repassword"
                      variant="outlined"
                      type={showPassword ? "text" : "password"}
                      onChange={e => {
                        setRePassword(e.target.value);
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={e => {
                              setShowPassword(!showPassword);
                            }}
                            //onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {password !== rePassword ? (
                      <FormHelperText id="repassword-helper-text" error>
                        パスワードが一致していません
                      </FormHelperText>
                    ) : (
                      <div></div>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  {isLoading ? (
                    <CircularProgress
                      size={26}
                      className={classes.loginLoader}
                    />
                  ) : (
                    <Button
                      variant="contained"
                      fullWidth
                      color="primary"
                      startIcon={<SaveIcon />}
                      disabled={
                        password !== rePassword ||
                        password.length === 0 ||
                        email.length === 0 ||
                        password.length < 8
                      }
                      onClick={e => {
                        handlePasswordUpdateClick();
                      }}
                    >
                      パスワード変更
                    </Button>
                  )}
                </Grid>
              </Grid>
            </div>
          </Widget>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        key={"bottom,right"}
        open={isSnackbarOpen}
        onClose={e => {
          setIsSnackbarOpen(false);
        }}
        message="操作が正常に完了しました。"
      />
    </>
  );
}
