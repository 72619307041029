import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  TextField,
  IconButton,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { Edit as EditIcon, Search as SearchIcon } from "@material-ui/icons";
import MUIDataTable from "mui-datatables";
import axios from "axios";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";

import CreateButton from "./components/CreateButton";

// styles
import useStyles from "./styles";

export default function Index(props) {
  const token = localStorage.getItem("jwt_token");
  var [templates, setTemplates] = useState([]);
  var [keyword, setKeyword] = useState("");
  var [isLoading, setIsLoading] = useState(false);
  var classes = useStyles();
  const columns = [
    {
      name: "edit",
      label: " ",
      options: {
        filter: false,
        sort: false,
        download: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div onClick={e => handleEditClick(e, tableMeta)}>
              <IconButton color={"secondary"} size={"small"}>
                <EditIcon />
              </IconButton>
            </div>
          );
        },
      },
    },
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
      },
    },
    {
      name: "title",
      label: "タイトル",
      options: {
        filter: true,
      },
    },
    {
      name: "subject",
      label: "件名",
      options: {
        filter: true,
      },
    },
    {
      name: "createdAt",
      label: "作成日",
      options: {
        filter: false,
      },
    },
  ];

  const handleEditClick = (e, data) => {
    props.history.push(`/app/mail/templates/${data.rowData[1]}`);
  };

  const fetchData = useCallback(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/mail/templates`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
        params: {},
        data: {},
      })
      .then(results => {
        setTemplates(results.data);
        return;
      })
      .catch(error => {
        if (error.response.status === 401) {
          localStorage.removeItem("jwt_token");
          
          window.location.reload();
          return;
        }
        alert(error.response.data.errors);
      });
  }, [setTemplates, token]);

  const handleSubmit = e => {
    e.preventDefault();
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/mail/templates`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
        params: { keyword: keyword },
        data: {},
      })
      .then(results => {
        setIsLoading(false);
        setTemplates(results.data);
        return;
      })
      .catch(error => {
        if (error.response.status === 401) {
          localStorage.removeItem("jwt_token");
          
          window.location.reload();
          return;
        }
        alert(error.response.data.errors);
      });
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <React.Fragment>
      <PageTitle title="メールテンプレート管理" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget disableWidgetMenu>
            <form id="search-form">
              <Grid container spacing={4}>
                <Grid item xs={12} sm={8}>
                  <TextField
                    fullWidth
                    id="search-keyword"
                    type="search"
                    label="検索キーワード"
                    variant="outlined"
                    size="small"
                    onChange={e => {
                      setKeyword(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  {isLoading ? (
                    <CircularProgress size={26} className={classes.isLoader} />
                  ) : (
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      type="submit"
                      startIcon={<SearchIcon />}
                      onClick={e => {
                        handleSubmit(e);
                      }}
                    >
                      検索
                    </Button>
                  )}
                </Grid>
                <Grid item xs={12} sm={2}>
                  <CreateButton {...props} />
                </Grid>
              </Grid>
            </form>
          </Widget>
        </Grid>
        <Grid item xs={12}>
          <MUIDataTable
            data={templates}
            columns={columns}
            options={{
              selectableRows: "none",
              filter: false,
              filterType: "checkbox",
              search: false,
              sort: true,
              rowHover: true,
              pagination: true,
              print: false,
              fixedHeader: true,
              rowsPerPage: 30,
              rowsPerPageOptions: [15, 30, 50, 100, 300],
            }}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
