import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  Snackbar,
  TextField,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { Save as SaveIcon } from "@material-ui/icons";
import axios from "axios";

// components
import PageTitle from "../../../components/PageTitle/PageTitle";
import Widget from "../../../components/Widget/Widget";

// styles
import useStyles from "./styles";

export default function Index(props) {
  const templateId = props.match.params.id;
  const token = localStorage.getItem("jwt_token");
  const isAdmin = localStorage.getItem("is_admin");
  var classes = useStyles();
  var [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  var [isLoading, setIsLoading] = useState(false);
  var [title, setTitle] = useState("");
  var [isTitleError, setIsTitleError] = useState(false);
  var [subject, setSubject] = useState("");
  var [isSubjectError, setIsSubjectError] = useState(false);
  var [body, setBody] = useState("");
  var [isBodyError, setIsBodyError] = useState(false);
  const data =
    "<<full_name>> 氏名\n\n<<plan_name>> プラン名\n\n<<common_name>> コモンネーム\n\n<<today>> 日付\n\n<<term>> 有効期限";

  const fetchInitData = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/v1/mail/templates/${templateId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
          data: {},
        },
      )
      .then(results => {
        setSubject(results.data.subject);
        setBody(results.data.body);
        setTitle(results.data.title);
      })
      .catch(error => {
        if (error.response.status === 401) {
          localStorage.removeItem("jwt_token");
          
          window.location.reload();
          return;
        }
        alert(error.response.data.errors);
      });
  }, [token, templateId, setSubject, setBody]);

  useEffect(() => {
    fetchInitData();
  }, [fetchInitData]);

  const handleSaveClick = e => {
    setIsLoading(true);
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/api/v1/mail/templates/${templateId}`,
        {
          title: title,
          subject: subject,
          body: body,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
        },
      )
      .then(results => {
        setIsLoading(false);
        setIsSnackbarOpen(true);
        return;
      })
      .catch(error => {
        if (error.response.status === 401) {
          localStorage.removeItem("jwt_token");
          
          window.location.reload();
          return;
        }
        alert(error.response.data.errors);
      });
  };

  return (
    <React.Fragment>
      <PageTitle title="メールテンプレート管理 > メールテンプレート編集" />
      <Grid container spacing={0}>
        <Grid item xs={9}>
          <Widget disableWidgetMenu>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12}>
                <Grid
                  container
                  spacing={4}
                  alignItems="flex-end"
                  justify="flex-end"
                >
                  {(() => {
                    if (isAdmin === "1") {
                      return (
                        <Grid item xs={12} sm={2}>
                          {isLoading ? (
                            <CircularProgress
                              size={26}
                              className={classes.isLoader}
                            />
                          ) : (
                            <Button
                              fullWidth
                              variant="contained"
                              color="primary"
                              size="large"
                              startIcon={<SaveIcon />}
                              onClick={e => {
                                handleSaveClick(e);
                              }}
                            >
                              更新
                            </Button>
                          )}
                        </Grid>
                      );
                    } else {
                      return <div></div>;
                    }
                  })()}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  required
                  error={isTitleError}
                  type="text"
                  id="edit-template-title"
                  label="タイトル"
                  variant="outlined"
                  size="small"
                  value={title}
                  onChange={e => {
                    setTitle(e.target.value);
                    if (e.target.value) {
                      setIsTitleError(false);
                    } else {
                      setIsTitleError(true);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  required
                  error={isSubjectError}
                  type="text"
                  id="edit-template-subject"
                  label="件名"
                  variant="outlined"
                  size="small"
                  value={subject}
                  onChange={e => {
                    setSubject(e.target.value);
                    if (e.target.value) {
                      setIsSubjectError(false);
                    } else {
                      setIsSubjectError(true);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  required
                  error={isBodyError}
                  type="text"
                  id="edit-template-body"
                  label="本文"
                  variant="outlined"
                  multiline
                  rows={50}
                  value={body}
                  onChange={e => {
                    setBody(e.target.value);
                    if (e.target.value) {
                      setIsBodyError(false);
                    } else {
                      setIsBodyError(true);
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Widget>
        </Grid>
        <Grid item xs={3}>
          <Widget disableWidgetMenu title="置換項目一覧">
            <TextField
              fullWidth
              disabled
              type="text"
              id="create-template-body"
              variant="outlined"
              multiline
              rows={60}
              value={data}
            />
          </Widget>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        key={"bottom,right"}
        open={isSnackbarOpen}
        onClose={e => {
          setIsSnackbarOpen(false);
        }}
        message="更新が正常に完了しました。"
      />
    </React.Fragment>
  );
}
