import React, { useState, useEffect, useReducer, useCallback } from "react";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
  Select,
  Typography,
} from "@material-ui/core";
import {
  DateRange as DateRangeIcon,
  Edit as EditIcon,
  Save as SaveIcon,
} from "@material-ui/icons";
import axios from "axios";
import moment from "moment";

// components
import PageTitle from "../../../components/PageTitle/PageTitle";
import Widget from "../../../components/Widget/Widget";

import Memo from "./components/Memo";
import OrderMenu from "./components/OrderMenu";
import Notification from "./components/Notification";
import MailHistory from "./components/MailHistory";
import SelectMailForm from "./components/SelectMailForm";
import FileSection from "./components/FileSection";

// lib
import { states } from "../../../libs/States";
import {
  statusConst,
  actionConst,
  paymentMethodConst,
  lifetimeConst,
  afflTypeConst,
  partnerConst,
  installaticonConst,
} from "../../../libs/Const";
import {
  initialProduct,
  productReducer,
  initialCert,
  certReducer,
  initialCsr,
  csrReducer,
  initialInvoice,
  invoiceReducer,
  initialPic,
  picReducer,
  initialOrg,
  orgReducer,
  initialOrgPic,
  orgPicReducer,
} from "./libs/Reducers";

// styles
import useStyles from "./styles";

export default function Edit(props) {
  const orderId = props.match.params.id;
  const token = localStorage.getItem("jwt_token");
  const isAdmin = localStorage.getItem("is_admin");
  const classes = useStyles();
  const [product, productDispatch] = useReducer(productReducer, initialProduct);
  const [cert, certDispatch] = useReducer(certReducer, initialCert);
  const [invoice, invoiceDispatch] = useReducer(invoiceReducer, initialInvoice);
  const [pic, picDispatch] = useReducer(picReducer, initialPic);
  const [org, orgDispatch] = useReducer(orgReducer, initialOrg);
  const [orgPic, orgPicDispatch] = useReducer(orgPicReducer, initialOrgPic);
  const [csr, csrDispatch] = useReducer(csrReducer, initialCsr);
  const [isEdit, setIsEdit] = useState(false);
  const [staffs, setStaffs] = useState([]);
  const [productOptions, setProductOptions] = useState([]);

  const fetchInitData = useCallback(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/orders/${orderId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
        params: {},
        data: {},
      })
      .then(results => {
        productDispatch({
          type: "SET",
          order_id: parseInt(results.data.product.order_id),
          partner_id: parseInt(results.data.product.partner_id),
          brand_id: parseInt(results.data.product.brand_id),
          plan_id: parseInt(results.data.product.plan_id),
          product_id: parseInt(results.data.product.product_id),
          tracking_id: results.data.product.tracking_id,
          staff_id: parseInt(results.data.product.staff_id),
          license_count: parseInt(results.data.product.license_count),
          lifetime: results.data.product.lifetime,
          payment_method_id: parseInt(results.data.product.payment_method_id),
          action: results.data.product.action,
          note: results.data.product.note,
          installaticon: results.data.product.installaticon,
          plan_name: results.data.product.plan_name,
          status: parseInt(results.data.product.status),
          created_at: moment(
            Date.parse(results.data.product.created_at),
          ).format("YYYY-MM-DD HH:mm:ss"),
        });
        setProductOptions(results.data.product_options);
        invoiceDispatch({
          type: "SET",
          total: results.data.invoice.total,
          subtotal: results.data.invoice.subtotal,
          tax: results.data.invoice.tax,
        });
        certDispatch({
          type: "SET",
          common_name: results.data.cert.common_name,
          en_org: results.data.cert.en_org,
          en_org_unit: results.data.cert.en_org_unit,
          state: results.data.cert.state,
          locality: results.data.cert.locality,
          password: results.data.cert.password,
          cert: results.data.cert.cert,
          expired_at: moment(Date.parse(results.data.cert.expired_at)).format(
            "YYYY-MM-DD",
          ),
        });
        picDispatch({
          type: "SET",
          affl_type: results.data.pic.affl_type,
          email: results.data.pic.email,
          last_name: results.data.pic.last_name,
          first_name: results.data.pic.first_name,
          kana_last_name: results.data.pic.kana_last_name,
          kana_first_name: results.data.pic.kana_first_name,
          org: results.data.pic.org,
          kana_org: results.data.pic.kana_org,
          phone: results.data.pic.phone,
        });
        orgDispatch({
          type: "SET",
          web_server_type: results.data.org.web_server_type,
          email: results.data.org.email,
          phone: results.data.org.phone,
          org: results.data.org.org,
          kana_org: results.data.org.kana_org,
          en_org: results.data.org.en_org,
          org_unit: results.data.org.org_unit,
          kana_org_unit: results.data.org.kana_org_unit,
          en_org_unit: results.data.org.en_org_unit,
          postcode: results.data.org.postcode,
          state: results.data.org.state,
          city: results.data.org.city,
          en_city: results.data.org.en_city,
          address: results.data.org.address,
          en_address: results.data.org.en_address,
          duns_number: results.data.org.duns_number,
          corp_number: results.data.org.corp_number,
          tdb_code: results.data.org.tdb_code,
          tsr_code: results.data.org.tsr_code,
          current_cert: results.data.org.current_cert,
          ceo: results.data.org.ceo,
          chief_last_name: results.data.org.chief_last_name,
          kana_chief_last_name: results.data.org.kana_chief_last_name,
          chief_first_name: results.data.org.chief_first_name,
          kana_chief_first_name: results.data.org.kana_chief_first_name,
          chief_org_unit: results.data.org.chief_org_unit,
          chief_role: results.data.org.chief_role,
          signer_email: results.data.org.signer_email,
          signer_last_name: results.data.org.signer_last_name,
          signer_first_name: results.data.org.signer_first_name,
          signer_org_unit: results.data.org.signer_org_unit,
          signer_phone: results.data.org.signer_phone,
          signer_role: results.data.org.signer_role,
          en_seal_address: results.data.org.en_seal_address,
          en_seal_building: results.data.org.en_seal_building,
          en_signer_last_name: results.data.org.en_signer_last_name,
          en_signer_first_name: results.data.org.en_signer_first_name,
        });
        orgPicDispatch({
          type: "SET",
          email: results.data.org_pic.email,
          org: results.data.org_pic.org,
          kana_org: results.data.org_pic.kana_org,
          en_org: results.data.org_pic.en_org,
          org_unit: results.data.org_pic.org_unit,
          kana_org_unit: results.data.org_pic.kana_org_unit,
          en_org_unit: results.data.org_pic.en_org_unit,
          role: results.data.org_pic.role,
          en_role: results.data.org_pic.en_role,
          last_name: results.data.org_pic.last_name,
          first_name: results.data.org_pic.first_name,
          kana_last_name: results.data.org_pic.kana_last_name,
          kana_first_name: results.data.org_pic.kana_first_name,
          en_last_name: results.data.org_pic.en_last_name,
          en_first_name: results.data.org_pic.en_first_name,
          postcode: results.data.org_pic.postcode,
          state: parseInt(results.data.org_pic.state),
          city: results.data.org_pic.city,
          en_city: results.data.org_pic.en_city,
          address: results.data.org_pic.address,
          en_address: results.data.org_pic.en_address,
          phone: results.data.org_pic.phone,
        });
        csrDispatch({
          type: "SET",
          csr: results.data.csr.csr,
          private_key: results.data.csr.private_key,
        });
      });
  }, [
    orderId,
    token,
    productDispatch,
    certDispatch,
    invoiceDispatch,
    picDispatch,
    orgDispatch,
    orgPicDispatch,
    csrDispatch,
  ]);

  const fetchInitStaffData = useCallback(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/staffs/admin`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
        data: {},
      })
      .then(results => {
        setStaffs(results.data.staffs);
      })
      .catch(error => {
        if (error.response.status === 401) {
          localStorage.removeItem("jwt_token");
          
          window.location.reload();
          return;
        }
        alert(error.response.data.errors);
      });
  }, [setStaffs, token]);

  const handleClick = async e => {
    await axios
      .put(
        `${process.env.REACT_APP_API_URL}/api/v1/orders/${orderId}`,
        {
          product: product,
          cert: cert,
          pic: pic,
          org: org,
          org_pic: orgPic,
          csr: csr,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
        },
      )
      .then(results => {
        setIsEdit(false);
        fetchInitData();
        window.location.reload();
        return;
      })
      .catch(error => {
        if (error.response.status === 401) {
          localStorage.removeItem("jwt_token");
          
          window.location.reload();
          return;
        }
        alert(error.response.data.errors);
      });
    return;
  };

  useEffect(() => {
    fetchInitStaffData();
    fetchInitData();
  }, [fetchInitStaffData, fetchInitData]);

  const handleCancelClick = e => {
    fetchInitData();
    setIsEdit(false);
    return;
  };

  const handleFetchCertificateExpireClick = async e => {
    if (!cert.cert) {
      alert("証明書が貼り付けられていません。");
      return;
    }

    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/v1/orders/${orderId}/check/certificate/expire`,
        {
          cert: cert.cert,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
        },
      )
      .then(results => {
        certDispatch({
          type: "UPDATE",
          value: results.data.expire,
          key: "expired_at",
        });
        return;
      })
      .catch(error => {
        if (error.response.status === 401) {
          localStorage.removeItem("jwt_token");
          
          window.location.reload();
          return;
        }
        alert(error.response.data.errors);
      });
  };

  return (
    <React.Fragment>
      <PageTitle title="注文管理 > 注文情報詳細" />
      <Grid container spacing={0}>
        {/* ボタン類 */}
        <Grid item xs={12}>
          <Widget disableWidgetMenu>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                {/* 発注ボタンなど */}
                <OrderMenu {...props} />
              </Grid>
              <Grid item xs={12}>
                {/* メールボタン */}
                <SelectMailForm {...props} />
              </Grid>
              {/* 編集ボタン */}
              <Grid item xs={12}>
                {(() => {
                  if (isAdmin === "1") {
                    if (isEdit) {
                      return (
                        <React.Fragment>
                          <Grid container spacing={4}>
                            <Grid item xs={6} sm={2}>
                              <Button
                                fullWidth
                                variant="outlined"
                                color="primary"
                                size="large"
                                onClick={handleCancelClick}
                              >
                                キャンセル
                              </Button>
                            </Grid>
                            <Grid item xs={6} sm={2}>
                              <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                size="large"
                                startIcon={<SaveIcon />}
                                onClick={e => {
                                  handleClick();
                                }}
                              >
                                保存
                              </Button>
                            </Grid>
                          </Grid>
                        </React.Fragment>
                      );
                    } else {
                      return (
                        <Grid
                          container
                          spacing={4}
                          alignItems="flex-end"
                          justify="flex-end"
                        >
                          <Grid item xs={12} sm={2}>
                            <Button
                              fullWidth
                              variant="contained"
                              size="large"
                              startIcon={<EditIcon />}
                              onClick={e => {
                                setIsEdit(true);
                              }}
                            >
                              編集
                            </Button>
                          </Grid>
                        </Grid>
                      );
                    }
                  }
                })()}
              </Grid>
            </Grid>
          </Widget>
        </Grid>
        {/* 内容 */}
        <Grid item xs={12} sm={9}>
          <Widget disableWidgetMenu>
            <Notification {...props} />
            <Grid container spacing={4}>
              {/* 基本情報 */}
              <Grid item xs={12}>
                <Typography variant="h5" color="textSecondary">
                  基本情報
                </Typography>
                <div className={classes.dashedBorder}>
                  <Grid container spacing={4}>
                    <Grid item xs={6} sm={3}>
                      <TextField
                        fullWidth
                        type="number"
                        id="order-id"
                        label="オーダーID"
                        variant="outlined"
                        size="small"
                        disabled
                        value={product.order_id}
                      />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <TextField
                        fullWidth
                        id="order-created"
                        type="text"
                        label="申込日時"
                        variant="outlined"
                        size="small"
                        disabled
                        value={product.created_at}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <FormControl fullWidth variant="outlined" size="small">
                        <InputLabel id="order-status-label">
                          進捗状況
                        </InputLabel>
                        <Select
                          labelId="order-status-label"
                          id="order-status"
                          label="進捗状況"
                          variant="outlined"
                          size="small"
                          disabled={!isEdit}
                          value={product.status}
                          onChange={e =>
                            productDispatch({
                              type: "UPDATE",
                              value: e.target.value,
                              key: "status",
                            })
                          }
                        >
                          <MenuItem value={0} key={0} disabled>
                            指定なし
                          </MenuItem>
                          {statusConst.map(v => (
                            <MenuItem value={v.code} key={v.code}>
                              {v.friendlyName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <FormControl fullWidth variant="outlined" size="small">
                        <InputLabel id="staff-label">担当者</InputLabel>
                        <Select
                          labelId="staff-label"
                          id="staff"
                          label="担当者"
                          variant="outlined"
                          size="small"
                          disabled={!isEdit}
                          value={product.staff_id || 0}
                          onChange={e =>
                            productDispatch({
                              type: "UPDATE",
                              value: e.target.value,
                              key: "staff_id",
                            })
                          }
                        >
                          <MenuItem value={0} key={0} disabled>
                            指定なし
                          </MenuItem>
                          {staffs.map(v => (
                            <MenuItem value={v.id} key={v.id}>
                              {v.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <FormControl fullWidth variant="outlined" size="small">
                        <InputLabel id="order-action-label">
                          申込種別
                        </InputLabel>
                        <Select
                          labelId="order-action-label"
                          id="order-action"
                          variant="outlined"
                          size="small"
                          label="申込種別"
                          disabled
                          value={product.action}
                        >
                          <MenuItem value={0} key={0} disabled>
                            指定なし
                          </MenuItem>
                          {actionConst.map(v => (
                            <MenuItem value={v.type} key={v.type}>
                              {v.friendlyName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <FormControl fullWidth variant="outlined" size="small">
                        <InputLabel id="order-partner-label">
                          パートナー
                        </InputLabel>
                        <Select
                          labelId="order-partner-label"
                          id="order-partner"
                          variant="outlined"
                          size="small"
                          label="パートナー"
                          disabled
                          value={product.partner_id}
                        >
                          <MenuItem value={0} key={0} disabled>
                            指定なし
                          </MenuItem>
                          {partnerConst.map(v => (
                            <MenuItem value={v.code} key={v.code}>
                              {v.friendlyName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <FormControl fullWidth variant="outlined" size="small">
                        <InputLabel id="order-installaticon-label">
                          設置先情報
                        </InputLabel>
                        <Select
                          labelId="order-installaticon-label"
                          id="order-installaticon"
                          label="設置先情報"
                          variant="outlined"
                          size="small"
                          disabled={!isEdit}
                          value={product.installaticon}
                          onChange={e =>
                            productDispatch({
                              type: "UPDATE",
                              value: e.target.value,
                              key: "installaticon",
                            })
                          }
                        >
                          <MenuItem value={0} key={0} disabled>
                            指定なし
                          </MenuItem>
                          {installaticonConst.map(v => (
                            <MenuItem value={v.type} key={v.type}>
                              {v.friendlyName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        fullWidth
                        type="text"
                        id="product-note"
                        label="ご質問・ご依頼"
                        variant="outlined"
                        size="small"
                        multiline
                        rows={7}
                        disabled
                        value={product.note || ""}
                        onChange={e =>
                          productDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "note",
                          })
                        }
                      />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              {/* 申込内容 */}
              <Grid item xs={12}>
                <Typography variant="h5" color="textSecondary">
                  申込内容
                </Typography>
                <div className={classes.dashedBorder}>
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={8}>
                      <TextField
                        fullWidth
                        type="text"
                        id="product-plan-name"
                        label="プラン"
                        variant="outlined"
                        size="small"
                        disabled
                        value={product.plan_name}
                      />
                    </Grid>
                    <Grid item xs={6} sm={2}>
                      <TextField
                        fullWidth
                        type="number"
                        id="product-license-count"
                        label="ライセンス数"
                        variant="outlined"
                        size="small"
                        disabled
                        value={product.license_count}
                      />
                    </Grid>
                    <Grid item xs={6} sm={2}>
                      <FormControl fullWidth variant="outlined" size="small">
                        <InputLabel id="product-lifetime-label">
                          年数
                        </InputLabel>
                        <Select
                          labelId="product-lifetime-label"
                          id="product-lifetime"
                          variant="outlined"
                          size="small"
                          label="年数"
                          disabled
                          value={product.lifetime}
                        >
                          <MenuItem value={0} key={0} disabled>
                            指定なし
                          </MenuItem>
                          {lifetimeConst.map(v => (
                            <MenuItem value={v.type} key={v.type}>
                              {v.friendlyName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    {productOptions.map(v => (
                      <React.Fragment key={v.name}>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            fullWidth
                            type="text"
                            id={"product-option-" + v.name}
                            label="オプションプラン"
                            variant="outlined"
                            size="small"
                            disabled
                            value={v.friendly_name}
                          />
                        </Grid>
                      </React.Fragment>
                    ))}
                  </Grid>
                </div>
              </Grid>
              {/* 支払情報 */}
              <Grid item xs={12}>
                <Typography variant="h5" color="textSecondary">
                  支払情報
                </Typography>
                <div className={classes.dashedBorder}>
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={3}>
                      <FormControl fullWidth variant="outlined" size="small">
                        <InputLabel id="payment-method-label">
                          支払方法
                        </InputLabel>
                        <Select
                          labelId="payment-method-label"
                          id="payment-method"
                          variant="outlined"
                          size="small"
                          label="支払方法"
                          disabled
                          value={product.payment_method_id}
                        >
                          <MenuItem value={0} key={0} disabled>
                            指定なし
                          </MenuItem>
                          {paymentMethodConst.map(v => (
                            <MenuItem value={v.type} key={v.type}>
                              {v.friendlyName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField
                        fullWidth
                        id="order-tracking-id"
                        type="text"
                        label="SBPSトラッキングID"
                        variant="outlined"
                        size="small"
                        disabled
                        value={product.tracking_id || ""}
                      />
                    </Grid>
                    <Grid item xs={4} sm={2}>
                      <TextField
                        fullWidth
                        type="number"
                        id="invoice-subtotal"
                        label="売上額"
                        variant="outlined"
                        size="small"
                        disabled
                        value={invoice.subtotal}
                      />
                    </Grid>
                    <Grid item xs={4} sm={2}>
                      <TextField
                        fullWidth
                        type="number"
                        id="invoice-tax"
                        label="消費税"
                        variant="outlined"
                        size="small"
                        disabled
                        value={invoice.tax}
                      />
                    </Grid>
                    <Grid item xs={4} sm={2}>
                      <TextField
                        fullWidth
                        type="number"
                        id="invoice-total"
                        label="請求額"
                        variant="outlined"
                        size="small"
                        disabled
                        value={invoice.total}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              {/* 証明書情報 */}
              <Grid item xs={12}>
                <Typography variant="h5" color="textSecondary">
                  証明書情報
                </Typography>
                <div className={classes.dashedBorder}>
                  {(() => {
                    if (isEdit) {
                      return (
                        <Grid container spacing={4}>
                          <Grid item xs={12} sm={3}>
                            <Button
                              fullWidth
                              variant="contained"
                              color="secondary"
                              size="large"
                              startIcon={<DateRangeIcon />}
                              onClick={e => {
                                handleFetchCertificateExpireClick(e);
                              }}
                            >
                              証明書有効期限取得
                            </Button>
                          </Grid>
                        </Grid>
                      );
                    }
                  })()}
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={5}>
                      <TextField
                        fullWidth
                        type="text"
                        id="cert-common-name"
                        label="コモンネーム"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={cert.common_name}
                        onChange={e =>
                          certDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "common_name",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <TextField
                        fullWidth
                        id="cert-expire"
                        type="date"
                        label="証明書有効期限"
                        variant="outlined"
                        size="small"
                        value={cert.expired_at || ""}
                        disabled={!isEdit}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={e =>
                          certDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "expired_at",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <TextField
                        fullWidth
                        type="text"
                        id="cert-password"
                        label="送付パスワード"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={cert.password}
                        onChange={e =>
                          certDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "password",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        type="text"
                        id="cert-en-org"
                        label="組織名（英）"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={cert.en_org}
                        onChange={e =>
                          certDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "en_org",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        type="text"
                        id="cert-en-org-unit"
                        label="部門名（英）"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={cert.en_org_unit}
                        onChange={e =>
                          certDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "en_org_unit",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth variant="outlined" size="small">
                        <InputLabel id="cert-en-state-label">
                          都道府県（英）
                        </InputLabel>
                        <Select
                          labelId="cert-en-state-label"
                          id="cert-en-state"
                          label="都道府県（英）"
                          placeholder="Osaka"
                          variant="outlined"
                          size="small"
                          disabled={!isEdit}
                          value={cert.state}
                          onChange={e =>
                            certDispatch({
                              type: "UPDATE",
                              value: e.target.value,
                              key: "state",
                            })
                          }
                        >
                          <MenuItem value={0} key={0} disabled>
                            指定なし
                          </MenuItem>
                          {states.map(v => (
                            <MenuItem value={v.zipcode} key={v.zipcode}>
                              {v.friendlyName}({v.enFriendlyName})
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        type="text"
                        id="cert-en-locality"
                        label="市区町村（英）"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={cert.locality}
                        onChange={e =>
                          certDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "locality",
                          })
                        }
                      />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              {/* 添付ファイル */}
              <Grid item xs={12}>
                <Typography variant="h5" color="textSecondary">
                  添付ファイル
                </Typography>
                <div className={classes.dashedBorder}>
                  <FileSection {...props} />
                </div>
              </Grid>
              {/* メール送信履歴 */}
              <Grid item xs={12}>
                <Typography variant="h5" color="textSecondary">
                  メール送信履歴
                </Typography>
                <div className={classes.dashedBorder}>
                  <MailHistory {...props} />
                </div>
              </Grid>
              {/* 申請者情報 */}
              <Grid item xs={12}>
                <Typography variant="h5" color="textSecondary">
                  申請者情報
                </Typography>
                <div className={classes.dashedBorder}>
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={4}>
                      <FormControl fullWidth variant="outlined" size="small">
                        <InputLabel id="pic-affl-type-label">種別</InputLabel>
                        <Select
                          labelId="pic-affl-type-label"
                          id="pic-affl-type"
                          label="種別"
                          placeholder="Osaka"
                          variant="outlined"
                          size="small"
                          disabled={!isEdit}
                          value={pic.affl_type}
                          onChange={e =>
                            picDispatch({
                              type: "UPDATE",
                              value: e.target.value,
                              key: "affl_type",
                            })
                          }
                        >
                          <MenuItem value={0} key={0} disabled>
                            指定なし
                          </MenuItem>
                          {afflTypeConst.map(v => (
                            <MenuItem value={v.type} key={v.type}>
                              {v.friendlyName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        type="text"
                        id="pic-email"
                        label="メールアドレス"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={pic.email}
                        onChange={e =>
                          picDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "email",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        type="text"
                        id="pic-phone"
                        label="電話番号"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={pic.phone}
                        onChange={e =>
                          picDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "phone",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        type="text"
                        id="pic-org"
                        label="組織名"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={pic.org}
                        onChange={e =>
                          picDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "org",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        type="text"
                        id="pic-org-kana"
                        label="組織名（かな）"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={pic.kana_org}
                        onChange={e =>
                          picDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "kana_org",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <TextField
                        fullWidth
                        type="text"
                        id="pic-last-name"
                        label="姓"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={pic.last_name}
                        onChange={e =>
                          picDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "last_name",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <TextField
                        fullWidth
                        type="text"
                        id="pic-first-name"
                        label="名"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={pic.first_name}
                        onChange={e =>
                          picDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "first_name",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <TextField
                        fullWidth
                        type="text"
                        id="pic-last-name-kana"
                        label="姓（かな）"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={pic.kana_last_name}
                        onChange={e =>
                          picDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "kana_last_name",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <TextField
                        fullWidth
                        type="text"
                        id="pic-first-name-kana"
                        label="名（かな）"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={pic.kana_first_name}
                        onChange={e =>
                          picDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "kana_first_name",
                          })
                        }
                      />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              {/* 組織情報 */}
              <Grid item xs={12}>
                <Typography variant="h5" color="textSecondary">
                  組織情報
                </Typography>
                <div className={classes.dashedBorder}>
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        type="text"
                        id="org-web-server-type"
                        label="ウェブサーバ種類"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={org.web_server_type}
                        onChange={e =>
                          orgDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "web_server_type",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        type="text"
                        id="org-email"
                        label="認証用メールアドレス"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={org.email}
                        onChange={e =>
                          orgDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "email",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        type="text"
                        id="org-phone"
                        label="電話番号"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={org.phone}
                        onChange={e =>
                          orgDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "phone",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        type="text"
                        id="org-org"
                        label="組織名"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={org.org}
                        onChange={e =>
                          orgDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "org",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        type="text"
                        id="org-org-kana"
                        label="組織名（かな）"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={org.kana_org}
                        onChange={e =>
                          orgDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "kana_org",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        type="text"
                        id="org-org-en"
                        label="組織名（英）"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={org.en_org}
                        onChange={e =>
                          orgDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "en_org",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        type="text"
                        id="org-org-unit"
                        label="部門名"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={org.org_unit}
                        onChange={e =>
                          orgDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "org_unit",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        type="text"
                        id="org-org-unit-kana"
                        label="部門名（かな）"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={org.kana_org_unit}
                        onChange={e =>
                          orgDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "kana_org_unit",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        type="text"
                        id="org-org-unit-en"
                        label="部門名（英）"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={org.en_org_unit}
                        onChange={e =>
                          orgDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "en_org_unit",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        type="text"
                        id="org-postcode"
                        label="郵便番号"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={org.postcode}
                        onChange={e =>
                          orgDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "postcode",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormControl fullWidth variant="outlined" size="small">
                        <InputLabel id="org-state-label">都道府県</InputLabel>
                        <Select
                          labelId="org-state-label"
                          id="org-state"
                          label="都道府県"
                          placeholder="大阪"
                          variant="outlined"
                          size="small"
                          disabled={!isEdit}
                          value={org.state}
                          onChange={e =>
                            orgDispatch({
                              type: "UPDATE",
                              value: e.target.value,
                              key: "state",
                            })
                          }
                        >
                          <MenuItem value={0} key={0} disabled>
                            指定なし
                          </MenuItem>
                          {states.map(v => (
                            <MenuItem value={v.zipcode} key={v.zipcode}>
                              {v.friendlyName}({v.enFriendlyName})
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        type="text"
                        id="org-city"
                        label="市区町村"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={org.city}
                        onChange={e =>
                          orgDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "city",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        type="text"
                        id="org-city-en"
                        label="市区町村（英）"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={org.en_city}
                        onChange={e =>
                          orgDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "en_city",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        type="text"
                        id="org-address"
                        label="住所"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={org.address}
                        onChange={e =>
                          orgDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "address",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        type="text"
                        id="org-address-en"
                        label="住所（英）"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={org.en_address}
                        onChange={e =>
                          orgDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "en_address",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <TextField
                        fullWidth
                        type="text"
                        id="org-duns-number"
                        label="DUNSナンバー"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={org.duns_number}
                        onChange={e =>
                          orgDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "duns_number",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <TextField
                        fullWidth
                        type="text"
                        id="org-tdb-code"
                        label="帝国データバンク企業コード"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={org.tdb_code}
                        onChange={e =>
                          orgDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "tdb_code",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <TextField
                        fullWidth
                        type="text"
                        id="org-tsr-code"
                        label="東京商工リサーチ企業コード"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={org.tsr_code}
                        onChange={e =>
                          orgDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "tsr_code",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <TextField
                        fullWidth
                        type="text"
                        id="org-corp-number"
                        label="会社法人等番号"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={org.corp_number}
                        onChange={e =>
                          orgDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "corp_number",
                          })
                        }
                      />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              {/* 組織担当者情報 */}
              <Grid item xs={12}>
                <Typography variant="h5" color="textSecondary">
                  組織担当者情報
                </Typography>
                <div className={classes.dashedBorder}>
                  <Grid container spacing={4}>
                    <Grid item xs={6} sm={2}>
                      <TextField
                        fullWidth
                        type="text"
                        id="org-pic-lastname"
                        label="姓"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={orgPic.last_name}
                        onChange={e =>
                          orgPicDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "last_name",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sm={2}>
                      <TextField
                        fullWidth
                        type="text"
                        id="org-pic-firstname"
                        label="名"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={orgPic.first_name}
                        onChange={e =>
                          orgPicDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "first_name",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sm={2}>
                      <TextField
                        fullWidth
                        type="text"
                        id="org-pic-lastname-kana"
                        label="姓（かな）"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={orgPic.kana_last_name}
                        onChange={e =>
                          orgPicDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "kana_last_name",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sm={2}>
                      <TextField
                        fullWidth
                        type="text"
                        id="org-pic-firstname-kana"
                        label="名（かな）"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={orgPic.kana_first_name}
                        onChange={e =>
                          orgPicDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "kana_first_name",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sm={2}>
                      <TextField
                        fullWidth
                        type="text"
                        id="org-pic-lastname-en"
                        label="姓（英）"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={orgPic.en_last_name}
                        onChange={e =>
                          orgPicDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "en_last_name",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sm={2}>
                      <TextField
                        fullWidth
                        type="text"
                        id="org-pic-firstname-en"
                        label="名（英）"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={orgPic.en_first_name}
                        onChange={e =>
                          orgPicDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "en_first_name",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        type="text"
                        id="org-pic-org"
                        label="組織名"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={orgPic.org}
                        onChange={e =>
                          orgPicDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "org",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        type="text"
                        id="org-pic-org-kana"
                        label="組織名（かな）"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={orgPic.kana_org}
                        onChange={e =>
                          orgPicDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "kana_org",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        type="text"
                        id="org-pic-org-en"
                        label="組織名（英）"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={orgPic.en_org}
                        onChange={e =>
                          orgPicDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "en_org",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        type="text"
                        id="org-pic-org-unit"
                        label="部門名"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={orgPic.org_unit}
                        onChange={e =>
                          orgPicDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "org_unit",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        type="text"
                        id="org-pic-org-unit-kana"
                        label="部門名（かな）"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={orgPic.kana_org_unit}
                        onChange={e =>
                          orgPicDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "kana_org_unit",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        type="text"
                        id="org-pic-org-unit-en"
                        label="部門名（英）"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={orgPic.en_org_unit}
                        onChange={e =>
                          orgPicDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "en_org_unit",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <TextField
                        fullWidth
                        type="text"
                        id="org-pic-role"
                        label="役職"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={orgPic.role}
                        onChange={e =>
                          orgPicDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "role",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <TextField
                        fullWidth
                        type="text"
                        id="org-pic-role-en"
                        label="役職（英）"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={orgPic.en_role}
                        onChange={e =>
                          orgPicDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "en_role",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        type="text"
                        id="org-pic-email"
                        label="メールアドレス"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={orgPic.email}
                        onChange={e =>
                          orgPicDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "email",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <TextField
                        fullWidth
                        type="text"
                        id="org-pic-phone"
                        label="電話番号"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={orgPic.phone}
                        onChange={e =>
                          orgPicDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "phone",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <TextField
                        fullWidth
                        type="text"
                        id="org-pic-postcode"
                        label="郵便番号"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={orgPic.postcode}
                        onChange={e =>
                          orgPicDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "postcode",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormControl fullWidth variant="outlined" size="small">
                        <InputLabel id="org-pic-state-label">
                          都道府県
                        </InputLabel>
                        <Select
                          labelId="org-pic-state-label"
                          id="org-pic-state"
                          label="都道府県"
                          variant="outlined"
                          size="small"
                          disabled={!isEdit}
                          value={orgPic.state || 0}
                          onChange={e =>
                            orgPicDispatch({
                              type: "UPDATE",
                              value: e.target.value,
                              key: "state",
                            })
                          }
                        >
                          <MenuItem value={0} key={0} disabled>
                            指定なし
                          </MenuItem>
                          {states.map(v => (
                            <MenuItem value={v.zipcode} key={v.zipcode}>
                              {v.friendlyName}({v.enFriendlyName})
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        type="text"
                        id="org-pic-city"
                        label="市区町村"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={orgPic.city}
                        onChange={e =>
                          orgPicDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "city",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        type="text"
                        id="org-pic-city-en"
                        label="市区町村（英）"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={orgPic.en_city}
                        onChange={e =>
                          orgPicDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "en_city",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        type="text"
                        id="org-pic-address"
                        label="住所"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={orgPic.address}
                        onChange={e =>
                          orgPicDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "address",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        type="text"
                        id="org-pic-address-en"
                        label="住所（英）"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={orgPic.en_address}
                        onChange={e =>
                          orgPicDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "en_address",
                          })
                        }
                      />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              {/* 組織情報: 契約書署名者 */}
              <Grid item xs={12}>
                <Typography variant="h5" color="textSecondary">
                  組織情報: 契約書署名者
                </Typography>
                <div className={classes.dashedBorder}>
                  <Grid container spacing={4}>
                    <Grid item xs={6} sm={6}>
                      <TextField
                        fullWidth
                        type="text"
                        id="org-signer-email"
                        label="メールアドレス"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={org.signer_email}
                        onChange={e =>
                          orgDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "signer_email",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <TextField
                        fullWidth
                        type="text"
                        id="org-signer-phone"
                        label="電話番号"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={org.signer_phone}
                        onChange={e =>
                          orgDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "signer_phone",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <TextField
                        fullWidth
                        type="text"
                        id="org-signer-lastname"
                        label="姓"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={org.signer_last_name}
                        onChange={e =>
                          orgDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "signer_last_name",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <TextField
                        fullWidth
                        type="text"
                        id="org-signer-firstname"
                        label="名"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={org.signer_first_name}
                        onChange={e =>
                          orgDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "signer_first_name",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <TextField
                        fullWidth
                        type="text"
                        id="org-signer-lastname-en"
                        label="姓（英）"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={org.en_signer_last_name}
                        onChange={e =>
                          orgDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "en_signer_last_name",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <TextField
                        fullWidth
                        type="text"
                        id="org-signer-firstname-en"
                        label="名（英）"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={org.en_signer_first_name}
                        onChange={e =>
                          orgDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "en_signer_first_name",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <TextField
                        fullWidth
                        type="text"
                        id="org-signer-org-unit"
                        label="部門名"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={org.signer_org_unit}
                        onChange={e =>
                          orgDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "signer_org_unit",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <TextField
                        fullWidth
                        type="text"
                        id="org-signer-role"
                        label="役職"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={org.signer_role}
                        onChange={e =>
                          orgDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "signer_role",
                          })
                        }
                      />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              {/* 組織情報: 責任者 */}
              <Grid item xs={12}>
                <Typography variant="h5" color="textSecondary">
                  組織情報: 責任者
                </Typography>
                <div className={classes.dashedBorder}>
                  <Grid container spacing={4}>
                    <Grid item xs={6} sm={3}>
                      <TextField
                        fullWidth
                        type="text"
                        id="org-chief-lastname"
                        label="姓"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={org.chief_last_name}
                        onChange={e =>
                          orgDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "chief_last_name",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <TextField
                        fullWidth
                        type="text"
                        id="org-chief-firstname"
                        label="名"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={org.chief_first_name}
                        onChange={e =>
                          orgDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "chief_first_name",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <TextField
                        fullWidth
                        type="text"
                        id="org-chief-lastname-kana"
                        label="姓（かな）"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={org.kana_chief_last_name}
                        onChange={e =>
                          orgDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "kana_chief_last_name",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <TextField
                        fullWidth
                        type="text"
                        id="org-chief-firstname-kana"
                        label="名（かな）"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={org.kana_chief_first_name}
                        onChange={e =>
                          orgDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "kana_chief_first_name",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <TextField
                        fullWidth
                        type="text"
                        id="org-chief-org-unit"
                        label="部門名"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={org.chief_org_unit}
                        onChange={e =>
                          orgDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "chief_org_unit",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <TextField
                        fullWidth
                        type="text"
                        id="org-chief-role"
                        label="役職"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={org.chief_role}
                        onChange={e =>
                          orgDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "chief_role",
                          })
                        }
                      />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              {/* 組織情報: サイトシール情報 */}
              <Grid item xs={12}>
                <Typography variant="h5" color="textSecondary">
                  組織情報: サイトシール情報
                </Typography>
                <div className={classes.dashedBorder}>
                  <Grid container spacing={4}>
                    <Grid item xs={6} sm={6}>
                      <TextField
                        fullWidth
                        type="text"
                        id="org-seal-lastname-en"
                        label="姓（英）"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={org.en_signer_last_name}
                        onChange={e =>
                          orgDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "en_signer_last_name",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <TextField
                        fullWidth
                        type="text"
                        id="org-seal-firstname-en"
                        label="名（英）"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={org.en_signer_first_name}
                        onChange={e =>
                          orgDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "en_signer_first_name",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <TextField
                        fullWidth
                        type="text"
                        id="org-seal-address-en"
                        label="住所（英）"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={org.en_seal_address}
                        onChange={e =>
                          orgDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "en_seal_address",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <TextField
                        fullWidth
                        type="text"
                        id="org-seal-building"
                        label="ビル名（英）"
                        variant="outlined"
                        size="small"
                        disabled={!isEdit}
                        value={org.en_seal_building}
                        onChange={e =>
                          orgDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "en_seal_building",
                          })
                        }
                      />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              {/* NewCert */}
              <Grid item xs={12}>
                <Typography variant="h5" color="textSecondary">
                  新規証明書
                </Typography>
                <div className={classes.dashedBorder}>
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        fullWidth
                        type="text"
                        id="cert-cert"
                        label="証明書(Cert)"
                        placeholder="-----BEGIN CERTIFICATE----- ..."
                        variant="outlined"
                        size="small"
                        multiline
                        rows={15}
                        disabled={!isEdit}
                        value={cert.cert || ""}
                        onChange={e =>
                          certDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "cert",
                          })
                        }
                      />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              {/* CSR & PrivateKey & CurrentCert */}
              <Grid item xs={12}>
                <Typography variant="h5" color="textSecondary">
                  CSR/プライベートキー/使用中の証明書
                </Typography>
                <div className={classes.dashedBorder}>
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        type="text"
                        id="csr-csr"
                        placeholder="-----BEGIN CERTIFICATE REQUEST----- ..."
                        label="CSR"
                        variant="outlined"
                        size="small"
                        multiline
                        rows={15}
                        disabled={!isEdit}
                        value={csr.csr || ""}
                        onChange={e =>
                          csrDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "csr",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        type="text"
                        id="csr-privatekey"
                        label="プライベートキー"
                        placeholder="-----BEGIN PRIVATE KEY----- ..."
                        variant="outlined"
                        size="small"
                        multiline
                        rows={15}
                        disabled={!isEdit}
                        value={csr.private_key || ""}
                        onChange={e =>
                          csrDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "private_key",
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        fullWidth
                        type="text"
                        id="org-current-cert"
                        label="使用中の証明書"
                        placeholder="-----BEGIN CERTIFICATE----- ..."
                        variant="outlined"
                        size="small"
                        multiline
                        rows={15}
                        disabled={!isEdit}
                        value={org.current_cert || ""}
                        onChange={e =>
                          orgDispatch({
                            type: "UPDATE",
                            value: e.target.value,
                            key: "current_cert",
                          })
                        }
                      />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Widget>
        </Grid>
        {/* メモ */}
        <Grid item xs={12} sm={3}>
          <Widget disableWidgetMenu>
            <Memo {...props} />
          </Widget>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
