import React, { useState, useRef, useEffect, useReducer } from "react";
import {
  Grid,
  TextField,
  Button,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Snackbar,
} from "@material-ui/core";
import axios from "axios";
import moment from "moment";

// components
import PageTitle from "../../../components/PageTitle/PageTitle";
import Widget from "../../../components/Widget/Widget";

// lib
import { states } from "../../../libs/States";

const reducer = (form, action) => {
  switch (action.type) {
    case "SET": {
      return {
        ...form,
        id: action.id,
        email: action.email,
        lastName: action.lastName,
        firstName: action.firstName,
        lastNameKana: action.lastNameKana,
        firstNameKana: action.firstNameKana,
        org: action.org,
        orgKana: action.orgKana,
        phone: action.phone,
        postcode: action.postcode,
        state: action.state,
        city: action.city,
        address: action.address,
        createdAt: action.createdAt,
        updatedAt: action.updatedAt,
      };
    }
    case "UPDATE": {
      return {
        ...form,
        [action.key]: action.value,
      };
    }
    default:
      return form;
  }
};

export default function Edit(props) {
  var isFirstRef = useRef(true);
  var [form, dispatch] = useReducer(reducer, {
    id: 0,
    email: "",
    lastName: "",
    firstName: "",
    lastNameKana: "",
    firstNameKana: "",
    org: "",
    orgKana: "",
    phone: "",
    postcode: "",
    state: "",
    city: "",
    address: "",
    createdAt: "",
    updatedAt: "",
  });
  var [isEdit, setIsEdit] = useState(true);
  var [isOpen, setIsOpen] = useState(false);

  const token = localStorage.getItem("jwt_token");
  const id = props.match.params.id;
  const isAdmin = localStorage.getItem("is_admin");

  useEffect(() => {
    if (isFirstRef.current) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/api/v1/accounts/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
          params: {},
          data: {},
        })
        .then(results => {
          dispatch({
            type: "SET",
            id: parseInt(results.data.account_id),
            email: results.data.email,
            lastName: results.data.last_name,
            firstName: results.data.first_name,
            lastNameKana: results.data.kana_last_name,
            firstNameKana: results.data.kana_first_name,
            org: results.data.org,
            orgKana: results.data.kana_org,
            phone: results.data.phone,
            postcode: results.data.postcode,
            state: results.data.state,
            city: results.data.city,
            address: results.data.address,
            createdAt: moment(Date.parse(results.data.created_at)).format(
              "YYYY-MM-DD HH:mm:ss",
            ),
            updatedAt: moment(Date.parse(results.data.updated_at)).format(
              "YYYY-MM-DD HH:mm:ss",
            ),
          });
          return;
        })
        .catch(error => {
          if (error.response.status === 401) {
            localStorage.removeItem("jwt_token");
            
            window.location.reload();
            return;
          }
          alert(error.response.data.errors);
        });
    }

    return function cleanup() {
      isFirstRef.current = false;
    };
  });

  const handleClick = e => {
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/api/v1/accounts/${id}`,
        {
          email: form.email,
          first_name: form.firstName,
          last_name: form.lastName,
          kana_first_name: form.firstNameKana,
          kana_last_name: form.lastNameKana,
          org: form.org,
          kana_org: form.orgKana,
          phone: form.phone,
          postcode: form.postcode,
          city: form.city,
          state: form.state,
          address: form.address,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
        },
      )
      .then(results => {
        setIsEdit(true);
        setIsOpen(true);
        const updateAt = moment(Date.parse(results.data.updated_at)).format(
          "YYYY-MM-DD HH:mm:ss",
        );
        dispatch({
          type: "UPDATE",
          value: updateAt,
          key: "updatedAt",
        });
        return;
      })
      .catch(error => {
        if (error.response.status === 401) {
          localStorage.removeItem("jwt_token");
          
          window.location.reload();
          return;
        }
        alert(error.response.data.errors);
      });
  };

  return (
    <React.Fragment>
      <PageTitle title="顧客管理 > 顧客情報編集" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget disableWidgetMenu>
            {/* ボタン */}
            <div style={{ paddingBottom: 20 }}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  {(() => {
                    if (isAdmin === "1") {
                      if (isEdit) {
                        return (
                          <Grid
                            container
                            spacing={4}
                            alignItems="flex-end"
                            justify="flex-end"
                          >
                            <Grid item xs={12} sm={2}>
                              <Button
                                fullWidth
                                variant="contained"
                                size="large"
                                onClick={e => {
                                  setIsEdit(false);
                                }}
                              >
                                編集
                              </Button>
                            </Grid>
                          </Grid>
                        );
                      } else {
                        return (
                          <Grid container spacing={4}>
                            <Grid item xs={6} sm={2}>
                              <Button
                                fullWidth
                                variant="outlined"
                                size="large"
                                color="primary"
                                onClick={e => {
                                  setIsEdit(true);
                                }}
                              >
                                キャンセル
                              </Button>
                            </Grid>
                            <Grid item xs={6} sm={2}>
                              <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                size="large"
                                onClick={handleClick}
                              >
                                保存
                              </Button>
                            </Grid>
                          </Grid>
                        );
                      }
                    }
                  })()}
                </Grid>
              </Grid>
            </div>
            {/* メイン */}
            <Grid container spacing={4}>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  type="number"
                  id="account-id"
                  label="顧客ID"
                  variant="outlined"
                  size="small"
                  disabled
                  value={form.id || 0}
                  //onChange={e => setReqForm({...reqForm, id: e.target.value})}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  id="account-createdat"
                  //type="datetime-local"
                  type="text"
                  label="作成日時"
                  placeholder="2020-04-01"
                  variant="outlined"
                  size="small"
                  disabled
                  value={form.createdAt}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  id="account-updatedat"
                  type="text"
                  label="更新日時"
                  placeholder="2020-04-01"
                  variant="outlined"
                  size="small"
                  disabled
                  value={form.updatedAt}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="text"
                  id="account-email"
                  label="メールアドレス"
                  placeholder="ssl@bitstar.jp"
                  variant="outlined"
                  size="small"
                  disabled={isEdit}
                  value={form.email}
                  onChange={e =>
                    dispatch({
                      type: "UPDATE",
                      value: e.target.value,
                      key: "email",
                    })
                  }
                  //onChange={e => setReqForm({...reqForm, email: e.target.value})}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="text"
                  id="account-phone"
                  label="電話番号"
                  placeholder="0123-00-0000"
                  variant="outlined"
                  size="small"
                  disabled={isEdit}
                  value={form.phone}
                  onChange={e =>
                    dispatch({
                      type: "UPDATE",
                      value: e.target.value,
                      key: "phone",
                    })
                  }
                  //onChange={e => setReqForm({...reqForm, phone: e.target.value})}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <TextField
                  fullWidth
                  type="text"
                  id="account-last-name"
                  label="姓"
                  placeholder="田中"
                  variant="outlined"
                  size="small"
                  disabled={isEdit}
                  value={form.lastName}
                  onChange={e =>
                    dispatch({
                      type: "UPDATE",
                      value: e.target.value,
                      key: "lastName",
                    })
                  }
                  //onChange={e => setReqForm({...reqForm, lastName: e.target.value})}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <TextField
                  fullWidth
                  type="text"
                  id="account-first-name"
                  label="名"
                  placeholder="太郎"
                  variant="outlined"
                  size="small"
                  disabled={isEdit}
                  value={form.firstName}
                  onChange={e =>
                    dispatch({
                      type: "UPDATE",
                      value: e.target.value,
                      key: "firstName",
                    })
                  }
                  //onChange={e => setReqForm({...reqForm, firstName: e.target.value})}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <TextField
                  fullWidth
                  type="text"
                  id="account-last-name-kana"
                  label="姓（かな）"
                  placeholder="たなか"
                  variant="outlined"
                  size="small"
                  disabled={isEdit}
                  value={form.lastNameKana}
                  onChange={e =>
                    dispatch({
                      type: "UPDATE",
                      value: e.target.value,
                      key: "lastNameKana",
                    })
                  }
                  //onChange={e => setReqForm({...reqForm, lastNameKana: e.target.value})}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <TextField
                  fullWidth
                  type="text"
                  id="account-first-name-kana"
                  label="名（かな）"
                  placeholder="たろう"
                  variant="outlined"
                  size="small"
                  disabled={isEdit}
                  value={form.firstNameKana}
                  onChange={e =>
                    dispatch({
                      type: "UPDATE",
                      value: e.target.value,
                      key: "firstNameKana",
                    })
                  }
                  //onChange={e => setReqForm({...reqForm, firstNameKana: e.target.value})}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="text"
                  id="account-org"
                  label="組織名"
                  placeholder="ビットスター株式会社"
                  variant="outlined"
                  size="small"
                  disabled={isEdit}
                  value={form.org}
                  onChange={e =>
                    dispatch({
                      type: "UPDATE",
                      value: e.target.value,
                      key: "org",
                    })
                  }
                  //onChange={e => setReqForm({...reqForm, org: e.target.value})}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="text"
                  id="account-org-kana"
                  label="組織名（かな）"
                  placeholder="びっとすたーかぶしきがいしゃ"
                  variant="outlined"
                  size="small"
                  disabled={isEdit}
                  value={form.orgKana}
                  onChange={e =>
                    dispatch({
                      type: "UPDATE",
                      value: e.target.value,
                      key: "orgKana",
                    })
                  }
                  //onChange={e => setReqForm({...reqForm, orgKana: e.target.value})}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <TextField
                  fullWidth
                  type="text"
                  id="account-postcode"
                  label="郵便番号"
                  placeholder="530-0011"
                  variant="outlined"
                  size="small"
                  disabled={isEdit}
                  value={form.postcode}
                  onChange={e =>
                    dispatch({
                      type: "UPDATE",
                      value: e.target.value,
                      key: "postcode",
                    })
                  }
                  //onChange={e => setReqForm({...reqForm, postcode: e.target.value})}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <FormControl fullWidth variant="outlined" size="small">
                  <InputLabel id="account-state-label">都道府県</InputLabel>
                  <Select
                    labelId="account-state-label"
                    id="account-state"
                    label="都道府県"
                    placeholder="大阪"
                    variant="outlined"
                    size="small"
                    disabled={isEdit}
                    value={form.state || 0}
                    onChange={e =>
                      dispatch({
                        type: "UPDATE",
                        value: e.target.value,
                        key: "state",
                      })
                    }
                  >
                    <MenuItem value={0} key={0} disabled>
                      選択してください
                    </MenuItem>
                    {states.map(v => (
                      <MenuItem value={v.zipcode} key={v.zipcode}>
                        {v.friendlyName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="text"
                  id="account-city"
                  label="市区町村"
                  placeholder="大阪市北区"
                  variant="outlined"
                  size="small"
                  disabled={isEdit}
                  value={form.city}
                  onChange={e =>
                    dispatch({
                      type: "UPDATE",
                      value: e.target.value,
                      key: "city",
                    })
                  }
                  //onChange={e => setReqForm({...reqForm, city: e.target.value})}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="text"
                  id="account-address"
                  label="住所"
                  placeholder="大深町4-20 グランフロント大阪タワーA35F"
                  variant="outlined"
                  size="small"
                  disabled={isEdit}
                  value={form.address}
                  onChange={e =>
                    dispatch({
                      type: "UPDATE",
                      value: e.target.value,
                      key: "address",
                    })
                  }
                  //onChange={e => setReqForm({...reqForm, address: e.target.value})}
                />
              </Grid>
            </Grid>
          </Widget>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        key={"bottom,right"}
        open={isOpen}
        onClose={e => setIsOpen(false)}
        message="保存しました"
      />
    </React.Fragment>
  );
}
