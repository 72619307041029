import React, { useState } from "react";
import {
  Grid,
  Button,
  Input,
  CircularProgress,
  Typography,
  Snackbar,
} from "@material-ui/core";
import {
  PictureAsPdf as PictureAsPdfIcon,
  CloudUpload as CloudUploadIcon,
  InsertDriveFile as InsertDriveFileIcon,
} from "@material-ui/icons";
import axios from "axios";

// styles
import useStyles from "./styles";

export default function FileSectionDeliverable(props) {
  const token = localStorage.getItem("jwt_token");
  const orderId = props.match.params.id;
  const classes = useStyles();
  const [file, setFile] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isUploadEnable, setIsUploadEnable] = useState(false);
  const [isSnacbarOpen, setIsSnacbarOpen] = useState(false);

  const handleInputChange = e => {
    setIsUploadEnable(true);
    setFile(e.target.files[0]);
  };

  const handleSublit = async e => {
    setIsLoading(true);
    if (!file) {
      setIsLoading(false);
      setIsUploadEnable(false);
      alert("ファイルが選択されていません。");
      return;
    }
    const data = new FormData();
    data.append("file", file);
    data.append("upload_type", "4");
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/v1/orders/${orderId}/files`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `bearer ${token}`,
          },
        },
      )
      .then(results => {
        setIsLoading(false);
        setIsSnacbarOpen(true);
        props.fetchInitData();
      })
      .catch(error => {
        setIsLoading(false);
        if (error.response.status === 401) {
          localStorage.removeItem("jwt_token");
          
          window.location.reload();
          return;
        }
        alert(error.response.data.errors);
      });
  };

  const handleClick = async e => {
    setIsLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/v1/orders/${orderId}/files/create/deliverable`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
        },
      )
      .then(results => {
        setIsLoading(false);
        setIsSnacbarOpen(true);
        props.fetchInitData();
      })
      .catch(error => {
        setIsLoading(false);
        if (error.response.status === 401) {
          localStorage.removeItem("jwt_token");
          
          window.location.reload();
          return;
        }
        alert(error.response.data.errors);
      });
  };

  return (
    <React.Fragment>
      <Typography>納品ファイル(ZIP)</Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={3}>
          <Button
            fullWidth
            size="small"
            disabled={!props.isDeliverable}
            variant="contained"
            color="primary"
            startIcon={<PictureAsPdfIcon />}
            onClick={e => {
              window.location.href = `${process.env.REACT_APP_API_URL}/api/v1/orders/${orderId}/files/deliverable/${props.imgToken}`;
            }}
          >
            ダウンロード
          </Button>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Input
            type="file"
            fullWidth
            size="small"
            onChange={handleInputChange}
            inputProps={{
              accept: "application/zip",
            }}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          {isLoading ? (
            <CircularProgress size={26} className={classes.isLoader} />
          ) : (
            <Button
              type="submit"
              size="small"
              fullWidth
              disabled={!isUploadEnable}
              variant="contained"
              color="secondary"
              onClick={() => handleSublit()}
              startIcon={<CloudUploadIcon />}
            >
              アップロード
            </Button>
          )}
        </Grid>
        <Grid item xs={6} sm={2}>
          {isLoading ? (
            <CircularProgress size={26} className={classes.isLoader} />
          ) : (
            <Button
              size="small"
              fullWidth
              disabled={isUploadEnable}
              variant="outlined"
              color="secondary"
              startIcon={<InsertDriveFileIcon />}
              onClick={e => {
                handleClick(e);
              }}
            >
              生成
            </Button>
          )}
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        key={"bottom,right"}
        open={isSnacbarOpen}
        onClose={e => {
          setIsSnacbarOpen(false);
        }}
        message="正常に操作が完了しました"
      />
    </React.Fragment>
  );
}
