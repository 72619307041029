import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  Snackbar,
  TextField,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { Save as SaveIcon } from "@material-ui/icons";
import axios from "axios";

// components
import PageTitle from "../../../components/PageTitle/PageTitle";
import Widget from "../../../components/Widget/Widget";

// styles
import useStyles from "./styles";

export default function Index(props) {
  const caId = props.match.params.id;
  const token = localStorage.getItem("jwt_token");
  const isAdmin = localStorage.getItem("is_admin");
  var classes = useStyles();
  var [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  var [isLoading, setIsLoading] = useState(false);
  var [type, setType] = useState("");
  var [brand, setBrand] = useState("");
  var [key, setKey] = useState("");
  var [expire, setExpire] = useState("");
  var [isKeyError, setIsKeyError] = useState(false);

  const fetchInitData = useCallback(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/ca/${caId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
        data: {},
      })
      .then(results => {
        setBrand(results.data.brand);
        setType(results.data.type);
        setKey(results.data.key);
        setExpire(results.data.expiredAt);
      })
      .catch(error => {
        if (error.response.status === 401) {
          localStorage.removeItem("jwt_token");
          
          window.location.reload();
          return;
        }
        alert(error.response.data.errors);
      });
  }, [token, caId, setBrand, setType, setKey]);

  useEffect(() => {
    fetchInitData();
  }, [fetchInitData]);

  const handleSaveClick = e => {
    if (isKeyError) {
      return;
    }
    setIsLoading(true);
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/api/v1/ca/${caId}`,
        {
          key: key,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
        },
      )
      .then(results => {
        setIsLoading(false);
        setIsSnackbarOpen(true);
        fetchInitData();
        return;
      })
      .catch(error => {
        if (error.response.status === 401) {
          localStorage.removeItem("jwt_token");
          
          window.location.reload();
          return;
        }
        alert(error.response.data.errors);
      });
  };

  return (
    <React.Fragment>
      <PageTitle title="中間証明書管理 > 中間証明書編集" />
      <Grid container spacing={0}>
        <Grid item xs={9}>
          <Widget disableWidgetMenu>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12}>
                <Grid
                  container
                  spacing={4}
                  alignItems="flex-end"
                  justify="flex-end"
                >
                  {(() => {
                    if (isAdmin === "1") {
                      return (
                        <Grid item xs={12} sm={2}>
                          {isLoading ? (
                            <CircularProgress
                              size={26}
                              className={classes.isLoader}
                            />
                          ) : (
                            <Button
                              fullWidth
                              variant="contained"
                              color="primary"
                              size="large"
                              startIcon={<SaveIcon />}
                              disabled={key.length === 0}
                              onClick={e => {
                                handleSaveClick(e);
                              }}
                            >
                              更新
                            </Button>
                          )}
                        </Grid>
                      );
                    } else {
                      return <div></div>;
                    }
                  })()}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  disabled
                  type="text"
                  id="edit-brand"
                  label="有効期限"
                  variant="outlined"
                  size="small"
                  value={expire}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  disabled
                  type="text"
                  id="edit-brand"
                  label="ブランド"
                  variant="outlined"
                  size="small"
                  value={brand}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  disabled
                  type="text"
                  id="edit-type"
                  label="認証タイプ"
                  variant="outlined"
                  size="small"
                  value={type}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  required
                  error={isKeyError}
                  type="text"
                  id="edit-key"
                  label="中間証明書内容"
                  variant="outlined"
                  multiline
                  rows={50}
                  value={key}
                  onChange={e => {
                    setKey(e.target.value);
                    if (e.target.value) {
                      setIsKeyError(false);
                    } else {
                      setIsKeyError(true);
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Widget>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        key={"bottom,right"}
        open={isSnackbarOpen}
        onClose={e => {
          setIsSnackbarOpen(false);
        }}
        message="更新が正常に完了しました。"
      />
    </React.Fragment>
  );
}
