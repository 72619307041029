import React from "react";
import { Grid } from "@material-ui/core";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import CsrCreate from "./components/CsrCreate";
import CsrCreateByOrderId from "./components/CsrCreateByOrderId";

export default function Csr(props) {
  return (
    <React.Fragment>
      <PageTitle title="CSR作成フォーム" />
      <Grid container spacing={4}>
        {/* 注文一覧から作成 */}
        <Grid item xs={12}>
          <CsrCreateByOrderId />
        </Grid>
        {/* SSL証明書申請情報 */}
        <Grid item xs={12}>
          <CsrCreate />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
